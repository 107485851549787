// ChangePassword.js

import React, {useState} from 'react';
import TopNav from "./TopNav";
import {Link, useNavigate, useParams} from "react-router-dom";
import AdminRoute from "./AdminRoute";

function ChangePassword() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newRepeatPassword, setNewRepeatPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const {username} = useParams();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (newPassword !== newRepeatPassword) {
                setMessage('Uus parool ja kordus ei kattu!');
                return;
            }
            const token = localStorage.getItem('token');
            const payload = {username, currentPassword, newPassword};

            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/changePassword/${username}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '',
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();

            if (data.success) {
                setMessage(data.message);
            } else {
                setMessage(data.message);
            }
        } catch (error) {
            setError(true);
            setMessage(error.message);
        }

    };

    return (
        <div>
            <TopNav/>
            <AdminRoute/>
            <div className="row">
                <div className="large-12 columns">
                    <div className="panel" style={{fontSize: '100%'}}>
                        <h2>{`Muuda "${username}" parooli`}</h2>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="password"
                                placeholder="Kehtiv parool"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                            <input
                                type="password"
                                placeholder="Uus parool"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <input
                                type="password"
                                placeholder="Korda uut parooli"
                                value={newRepeatPassword}
                                onChange={(e) => setNewRepeatPassword(e.target.value)}
                            />
                            <button type="submit">Muuda parooli</button>
                            <button onClick={() => navigate(-1)}>Tühista</button>
                        </form>
                        {message && <p>{message}</p>}
                        {error && <p>{error}</p>}
                        <a onClick={() => navigate(-1)}>&lt; Tagasi</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;
