import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import TopNav from "./TopNav";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const { setAuthenticated } = useAuth();

    useEffect(() => {
        document.title = 'Eesti kalad | Logi sisse';
    }, []);

    const handleLogin = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({username, password}),
            });

            const data = await response.json();

            if (data.success) {
                localStorage.setItem('token', data.token);
                // console.log(data.message);
                // Redirect or navigate to another page after successful login if needed
                setAuthenticated(true);
                navigate('/admin');
            } else {
                setError(true);
                // console.log(data.message);
                setMessage(data.message);
            }

        } catch (error) {
            if (error.response) {
                // Server responded with a status other than 200 range
                setError(true);
                setMessage(error.response.data.message);
                // console.log(error.response.data.message);
            }
        }
    };

    return (
        <div>
            <TopNav/>
            <div className="row panel">
                <h2>Logi sisse</h2>
                <form id="login-form" onSubmit={(e) => {
                    e.preventDefault();
                    handleLogin();
                }}>
                    <input
                        type="text"
                        placeholder="Kasutaja"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        cy-data="username"
                    />
                    <input
                        type="password"
                        placeholder="Parool"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        cy-data="password"
                    />
                    <button type="submit">Logi sisse</button>
                    {message && <p>{message}</p>}
                </form>
            </div>
        </div>

    );
};

export default Login;
