import React, {useEffect, useState} from 'react';
import TopNav from './TopNav';
import {useAuth} from './AuthContext';
import {useNavigate} from 'react-router-dom';
import AdminRoute from './AdminRoute';
import { ArrowRight } from 'react-feather';

const ListPage = () => {
    const title = "Eesti kalaliigid (A-Z)";
    const [estList, setEstList] = useState([]);
    const [images, setImages] = useState(null);
    const {authenticated, setAuthenticated, handleLogout} = useAuth();
    const [message, setMessage] = useState([]);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const fetchData = async () => {
        fetch(`${process.env.REACT_APP_API_URL}/species`)
            .then((response) => response.json())
            .then((data) => {
                setEstList(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        fetch(`${process.env.REACT_APP_API_URL}/images`)
            .then((response) => response.json())
            .then((dataImages) => {
                setImages(dataImages);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        document.title = 'Eesti kalad | Nimekiri';
        fetchData();
    }, []);

    const renderContent = () => {
        if (!images || !Array.isArray(images) || !estList || !Array.isArray(estList)) {
            return null;
        }

        const matchedElements = [];

        images.forEach((image, imageIndex) => {
            estList.forEach((fish) => {
                if(fish.species_id <104) {
                    if (fish.fid_estfish_id === image.fid_estfish_id) {
                        matchedElements.push({
                            image,
                            fish,
                        });
                    }
                }
            });
        });

        // Sort the matchedElements array by estName
        matchedElements.sort((a, b) => a.fish.estName.localeCompare(b.fish.estName));

        return (
            <>
                {matchedElements.map((element, index) => {
                    const {image, fish} = element;
                    const imageSrc = `img/t/${image.imageSrc}_t.png`;

                    return (
                        <li key={index} className="mylist">
                            <a href={`Description?id=${fish.species_id}`}>
                                <img
                                    onContextMenu={(e) => e.preventDefault()}
                                    width="100px"
                                    src={imageSrc}
                                    alt={`${fish.estName}`}
                                />
                                <span className="listtext">
                                {fish.estName}, <i>{fish.latinName}</i>
                            </span>
                            </a>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {authenticated && (
                                <a className="button" href={`Description?id=${fish.species_id}`}
                                   style={{alignItems: 'center', display: 'inline-flex', margin: '0.3rem 0.5rem' }}>
                                    Muuda &nbsp; <ArrowRight/>
                                </a>
                            )}
                        </li>
                    );
                })}
            </>
        );
    };


    return (
        <>
            <TopNav/>
            <AdminRoute/>
            <div className="row">
                <div className="large-12 columns">
                    <div className="panel">
                        <h3>{title}</h3>
                        <br/>
                        <div>
                            {estList.length > 0 ? (<ul className="list">
                                {renderContent()}
                            </ul>) : (<p>Laadin liike.</p>)}
                        </div>
                        <br/>
                        <a onClick={() => navigate(-1)}>&lt; Tagasi</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListPage;
