import React, {useEffect, useState} from 'react';
import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';
import {useAuth} from "./AuthContext";
import TopNav from "./TopNav";
import {useParams} from "react-router-dom";

const Identify = () => {
    // Get the Photo ID from the URL
    const { photoId } = useParams();

    const [message, setMessage] = useState([]);
    const [error, setError] = useState(false);
    const {authenticated, setAuthenticated, handleLogout} = useAuth();

    const [speciesNames, setSpeciesNames] = useState([]);
    const [selectedSpecies, setSelectedSpecies] = useState('');
    const [photoPath, setPhotoPath] = useState('');

    useEffect(() => {
        fetchSpeciesNames();
        fetchIdentifyRoute(photoId);
    }, [photoId]);

    const fetchSpeciesNames = async () => {
        try {
            const token = localStorage.getItem('token');

            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/getSpeciesNames`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '',
                },
            });
            if (response.redirected) {
                setAuthenticated(false);
            }

            const data = await response.json();

            if (data.success) {
                setSpeciesNames(data.message);
                // When authenticated, setAuthenticated(true);
                setAuthenticated(true);
            } else {
                setMessage(data.message);
                setAuthenticated(false);
            }


        } catch (error) {
            setError(true);
            setMessage(error.message);
        }
    };

    const fetchIdentifyRoute = async (photoId) => {
        try {
            const token = localStorage.getItem('token');

            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/identify/${photoId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '',
                },
            });

            if (response.redirected) {
                setAuthenticated(false);
            }

            const data = await response.json();

            if (data.success) {
                setMessage(data.message);
                // When authenticated, setAuthenticated(true);
                setAuthenticated(true);
                setPhotoPath(`../../photos/${data.message[0].species_folder}/${data.message[0].photo}`);
            } else {
                setMessage(data.message);
                setAuthenticated(false);
            }

        } catch (error) {
            setError(true);
            setMessage(error.message);
        }
    };

    const handleSpeciesChange = (event) => {
        setSelectedSpecies(event.target.value);
    };

    const handleSave = async () => {
/*        console.log('Submitting data to server:');
        console.log('Foto ID:', photoId);
        console.log('Selected Species:', selectedSpecies);
        console.log('Photo Path:', photoPath);*/


        // If selected same species from dropdown that was before then don't continue with save
        if (selectedSpecies === message[0].estName) {
            // Send the user back to the photos page
            window.location.href = "/admin/photos";
        } else {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/updatePhoto`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token ? `Bearer ${token}` : '',
                    },
                    body: JSON.stringify({ photoId, selectedSpecies, token, photoPath }),
                });

                const data = await response.json();

                if (data.success) {
                    // Handle success response from the backend
                    setMessage(data.message);
                   // console.log('Success response from the backend', message);
                    // Send the user back to the photos page and include the message
                    window.location.href = `/admin/photos?message=${data.message}`;
                } else {
                    // Handle failure response from the backend
                   // console.log('Failure response from the backend');
                    // Send the user back to the photos page and include the message
                    window.location.href = `/admin/photos?message=${data.message}`;
                }
            } catch (error) {
                // Handle fetch error
                console.log('Fetch error');
            }
        }


    };

    return (
        <div>
            <TopNav/>
            <div className="row">
                <div className="large-12 columns">
                    <div className="panel">
                        {/* Display of received data */}
                        <p>Foto ID: {photoId}</p>
                        {/*// Check message length not to try to read properties of an empty array*/}
                        {message.length > 0 && (
                            <p>Nimetus: {message[0].estName}, <i>{message[0].latinName}</i></p>
                        )}
                        {message.length === 0 && <p>Laadin... või andmeid pole</p>}

                        {/* Dropdown for selecting fish species */}
                        <select id="speciesDropdown" onChange={handleSpeciesChange}>
                            <option value="">Vali liik</option>
                            {speciesNames.map((species, index) => (
                                <option key={index} value={species.estName}>
                                    {species.estName} - {species.latinName}
                                </option>
                            ))}
                        </select>

                        {/* Include the TransformWrapper and TransformComponent */}
                        <p>Suurenda, vähenda ja liiguta pilti, et kala paremini näha</p>
                        <TransformWrapper>
                            <TransformComponent>
                                {message.length > 0 && (
                                    <img
                                        src={photoPath}
                                        alt={`Zoomable image ${message[0].photo}`}
                                        style={{
                                            // Apply original image styles here
                                            display: 'block', // Ensures inline image behavior is removed
                                            width: '100%', // Maintain image width
                                            height: '100%', // Maintain image height
                                            objectFit: 'cover', // Preserve aspect ratio and cover container
                                            objectPosition: 'center', // Center the image
                                        }}
                                    />
                                )}
                                {message.length === 0 && (
                                    <p>Fotot ei õnnestunud laadida</p>
                                )}
                            </TransformComponent>
                        </TransformWrapper>

                        {/* Button to trigger save */}
                        <button onClick={handleSave}>Salvesta</button>
                        {/* Button to cancel*/}
                        <button onClick={() => {
                            window.location.href = '/admin/photos';
                        }}>Tühista</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Identify;