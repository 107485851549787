import React, { useState, useEffect, useCallback } from 'react';
import TopNav from "./TopNav";
import KeysData from './KeysData.js';
import AdminRoute from './AdminRoute';

const Keys = () => {
    const [pubid, setPubid] = useState(1);
    const [keylist, setKeylist] = useState([]);
    const [keys, setKeys] = useState(null);
    const [arpos1, setArpos1] = useState(-1);
    const [arpos2, setArpos2] = useState(-1);
    const [loading, setLoading] = useState(false);

    const fetchData = async (callback) => {
        setLoading(false);
        const data = KeysData;
        setKeys(data);
        if (callback) {
            callback(data);
        }
    };

    useEffect(() => {
        document.title = 'Eesti kalad | Nimekiri';
        setLoading(true);
    }, []);

    useEffect(() => {
        fetchData((keys) => {
            if (keys.length > 0) {
                setKeylist(keys);
            } else {
            }
        });

        const pubIdFromURL = parseInt(new URLSearchParams(window.location.search).get('pid'));
        if (isNaN(pubIdFromURL) || pubIdFromURL <= 0) {
            if (sessionStorage.getItem('last_key_id')) {
                setPubid(parseInt(sessionStorage.getItem('last_key_id')));
            }
        } else {
            setPubid(pubIdFromURL);
            sessionStorage.setItem('last_key_id', pubIdFromURL.toString());
        }
    }, []);


    const getKeyPosition = useCallback((keyid) => {
        for (let i = 0; i < keylist.length; i++) {
            if (keylist[i].key_id === keyid) {
                return i;
            }
        }
        return -1;
    }, [keylist]); // Include keylist in the dependency array

    useEffect(() => {
        const arpos1Value = getKeyPosition(pubid);
        const arpos2Value = getKeyPosition(pubid + 1);

        setArpos1(arpos1Value);
        setArpos2(arpos2Value);
    }, [pubid, getKeyPosition, keylist]);


    const openNextKey = (id) => {
        window.location.href = `Keys?pid=${id}`;
    };

    const showTheSpecies = (id) => {
        window.location.href = `Description?id=${id}`;
    };

    const listTheSpecies = (list) => {
        const res = list.join(',');
        window.location.href = `list?top=1&lid=${res}`;
    };


    return (
        <>
            <TopNav/>
            <AdminRoute/>

            <div className="row">
                <div className="large-12 columns">
                    <div className="panel">
                        <h3>Määraja</h3>
                        {arpos1 > -1 && arpos2 > -1 ? (
                            <>
                                {keylist[arpos1].parent_key > 0 && (
                                    <p>
                                        <button className="button"
                                                onClick={() => openNextKey(keylist[arpos1].parent_key)}>
                                            Liigu samm tagasi
                                        </button>
                                        &nbsp;&nbsp;
                                        <button className="button" onClick={() => openNextKey(1)}>
                                            Lähtesta
                                        </button>
                                    </p>
                                )}

                                <p style={{maxWidth: '800px'}}>{keylist[arpos1].desc}</p>
                                {keylist[arpos1].extra && (
                                    <p style={{fontSize: '93%'}}>
                                        <i>Lisainfo: {keylist[arpos1].extra}</i>
                                    </p>
                                )}

                                {keylist[arpos1].child_key > 0 ? (
                                    <p>
                                        <button className="button"
                                                onClick={() => openNextKey(keylist[arpos1].child_key)}>
                                            Liigu edasi
                                        </button>
                                        &nbsp;&nbsp;
                                        <button className="button"
                                                onClick={() => listTheSpecies(keylist[arpos1].species)}>
                                            Vaata sobivaid liike
                                        </button>
                                    </p>
                                ) : (
                                    <p>
                                        <button className="button"
                                                onClick={() => showTheSpecies(keylist[arpos1].species[0])}>
                                            Vaata leitud liiki
                                        </button>
                                    </p>
                                )}

                                <p style={{maxWidth: '800px'}}>{keylist[arpos2].desc}</p>
                                {keylist[arpos2].extra && (
                                    <p style={{fontSize: '93%'}}>
                                        <i>Lisainfo: {keylist[arpos2].extra}</i>
                                    </p>
                                )}

                                {keylist[arpos2].child_key > 0 ? (
                                    <p>
                                        <button className="button"
                                                onClick={() => openNextKey(keylist[arpos2].child_key)}>
                                            Liigu edasi
                                        </button>
                                        &nbsp;&nbsp;
                                        <button className="button"
                                                onClick={() => listTheSpecies(keylist[arpos2].species)}>
                                            Vaata sobivaid liike
                                        </button>
                                    </p>
                                ) : (
                                    <p>
                                        <button className="button"
                                                onClick={() => showTheSpecies(keylist[arpos2].species[0])}>
                                            Vaata leitud liiki
                                        </button>
                                    </p>
                                )}
                            </>
                        ) : (
                            <p>Selliseid määramistunnuseid ei leitud.</p>
                        )}

                        <br/>
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Keys;