import React, { useState, useEffect } from "react";
import { useAuth } from './AuthContext';

const AdminRoute = () => {
    const { authenticated, setAuthenticated, handleLogout } = useAuth();
    const [message, setMessage] = useState([]);
    const [error, setError] = useState(false);

    const fetchAdminRoute = async () => {
        try {
            const token = localStorage.getItem('token');

            const response = await fetch(`${process.env.REACT_APP_API_URL}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '',
                },
            });

            if (response.redirected) {
                // Handle redirects, navigate to response.url
                //window.location.href = response.url;
                setAuthenticated(false);
            }

            const data = await response.json();

            if (data.success) {
                setMessage(data.message);
                setAuthenticated(true);
            } else {
                setMessage(data.message);
                setAuthenticated(false);
            }

        } catch (error) {
            setError(true);
            setMessage(error.message);
        }
    };

    useEffect(() => {
        fetchAdminRoute();
    }, []);

};

export default AdminRoute;
