import React from 'react';

function Footer() {
    return (
        <div  className='panel' style={{ textAlign: 'center'}}>
            <div style={{ display: 'inline-block', margin: '10px' }}>
                <img src="/icon/kalateave_logo.jpg" alt="kalateave_logo" style={{ maxWidth: '110px' }} />
            </div>
            <div style={{ display: 'inline-block', margin: '10px' }}>
                <img src="/icon/EKF_Horis_lipp_suur.jpg" alt="EKF_Horis_lipp_suur" style={{ maxWidth: '200px' }} />
            </div>
            <div style={{ display: 'inline-block', margin: '10px' }}>
                <img src="/icon/EMKF-2014-2020.png" alt="EMKF-2014-2020" style={{ maxWidth: '250px' }} />
            </div>
            <div style={{ display: 'inline-block', margin: '10px' }}>
                <img src="/icon/EMKVF-2021-2027.jpg" alt="EMKVF-2021-2027" style={{ maxWidth: '250px' }} />
            </div>
            <div style={{ display: 'inline-block', margin: '10px' }}>
                <img src="/icon/wnl.png" alt="wnl" style={{ maxWidth: '60px' }} />
            </div>
        </div>
    );
}

export default Footer;
