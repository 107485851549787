import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import TopNav from './components/TopNav';
import Main from './components/Main';
import Keys from './components/Keys';
import KalaAju from './components/Kalaaju';
import Dict from './components/Dict';
import Info from './components/Info';
import Footer from "./components/Footer";
import Login from "./components/Login";
import Admin from "./components/Admin";
import { AuthProvider } from "./components/AuthContext";
import ListAll from "./components/ListAll";
import Desc from "./components/Description";
import List from "./components/List";
import Pp from "./components/Pp";
import Logs from "./components/Logs";
import Photos from "./components/Photos";
import Search from "./components/Search";
import Identify from "./components/Identify";
import AddUser from "./components/AddUser";
import ChangePassword from "./components/ChangePassword";

class App extends Component {
    constructor() {
        super();
        this.state = {
            response: '', // To store the server response
            data: [], // To store the data from the server
            dbErrorMessage: null, // To store the database error message
        };
    }

    componentDidMount() {
        this.checkDatabaseConnection();
    }

    checkDatabaseConnection = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/index.php`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            } else {
                const data = await response.json();
                if (!data.success && data.dbErrorMessage) {
                    this.setState({ dbErrorMessage: data.dbErrorMessage });
                }
            }
        } catch (error) {
            console.error('Error checking database connection', error);
        }

    }

    render() {
        const {response, data, dbErrorMessage } = this.state;

        return (
            <AuthProvider>
            <div>
                {dbErrorMessage && <div className="alert-box">{dbErrorMessage}</div>}
                <Router>
                        <Routes>
                            <Route path="/" exact element={<Main/>}/>
                            <Route path="/keys" element={<Keys/>}/>
                            <Route path="/kalaaju" element={<KalaAju/>}/>
                            <Route path="/dict" element={<Dict/>}/>
                            <Route path="/info" element={<Info/>}/>
                            <Route path="/pp" element={<Pp/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/admin" element={<Admin/>}/>
                            <Route path="/admin/photos" element={<Photos />} />
                            <Route path="/admin/logs" element={<Logs />} />
                            <Route path="/admin/identify/:photoId" element={<Identify />} />
                            <Route path="/admin/adduser" element={<AddUser />}/>
                            <Route path="/admin/changePassword/:username" element={<ChangePassword />}/>

                            <Route path="/listAll" element={<ListAll />} />
                            <Route path="/Description" element={<Desc />} />
                            <Route path="/Description/:chosen" /> {/*// Is this needed?*/}
                            <Route path="/List" element={<List />} />
                            <Route path="/Search" element={<Search />} />

                        </Routes>

                        <Footer/>
                </Router>
            </div>
            </AuthProvider>
        );
    }
}

export default App;
