import React, {useEffect, useState, useMemo} from 'react';
import TopNav from "./TopNav";
import {useAuth} from "./AuthContext";
import {useTable, useFilters, useGlobalFilter, usePagination} from 'react-table';
import {Link, useNavigate} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import AdminRoute from "./AdminRoute";

const Photos = () => {

    const [message, setMessage] = useState([]);
    const [messageFromIdentify, setMessageFromIdentify] = useState('');
    const [messageFromDelete, setMessageFromDelete] = useState('');
    const location = useLocation();
    const [error, setError] = useState(false);
    const {authenticated, setAuthenticated, handleLogout} = useAuth();
    const [loading, setLoading] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();


    useEffect(() => {
        document.title = 'Eesti kalad | Fotod';
        setLoading(true); // Show loader when fetching starts
        {authenticated && fetchPhotosRoute()};// If authenticated, fetch photos
        const searchParams = new URLSearchParams(location.search);
        const message = searchParams.get('message');

        if (message) {
            // If coming from Identify, set the message
            setMessageFromIdentify(message);
        } else {
            // If not coming from Identify, clear the message
            setMessageFromIdentify('');
        }
    }, [location.search, authenticated]); // Re-run when location.search or authenticated changes

    const fetchPhotosRoute = async () => {
        try {
            const token = localStorage.getItem('token');

            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/photos`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '',
                },
            });

            if (response.redirected) {
                // Handle redirects, navigate to response.url
                //window.location.href = response.url;
                setAuthenticated(false);
            }

            const data = await response.json();

            if (data.success) {
                // Ensure that table always gets an array for data
                setMessage(Array.isArray(data.message) ? data.message : []);
                // When authenticated, setAuthenticated(true);
                setAuthenticated(true);
                setLoading(false); // Hide loader when fetching ends
                // Scroll to top to see the message
                window.scrollTo(0, 0);
            } else {
                // Ensure that table always gets an array for data
                setMessage(Array.isArray(data.message) ? data.message : []);
                setAuthenticated(false);
                setLoading(false); // Hide loader when fetching ends
                // Scroll to top to see the message
                window.scrollTo(0, 0);
            }


        } catch (error) {
            setError(true);
            setMessage(error.message);
            setLoading(false); // Hide loader when fetching ends
        }
    };

    {/* Photos table */
    }
    const columns = React.useMemo(
        () => [
            {
                Header: 'Foto ID',
                accessor: 'photo_id',
            },
            {
                Header: 'Liigi ID',
                accessor: 'species_id',
            },
            {
                Header: 'Foto',
                accessor: 'photo',
            },
            {
                Header: 'EST nimi',
                accessor: 'estName',
            },
            {
                Header: 'LAT nimi',
                accessor: 'latinName',
            },
            {
                Header: 'Staatus',
                accessor: 'status',
            },
            {
                Header: 'Määraja',
                accessor: 'name',
            },
            {
                Header: 'Määramise aeg',
                accessor: 'verification_time',
            },
            {
                Header: 'Lisamise aeg',
                accessor: 'ident_time',
            },
            {
                Header: 'Kommentaar',
                accessor: 'comment',
            },
            {
                Header: 'Määra',
                Cell: ({row}) => (
                    <Link to={`/admin/identify/${row.original.photo_id}`}>
                        <button>Määra liik</button>
                    </Link>
                )
            },
            {
                Header: 'Kustuta',
                Cell: ({row}) => (
                    <button onClick={() => deletePhoto(row.original.photo_id)}>Kustuta</button>
                )
            }
        ],
        []
    );

    const data = React.useMemo(() => message || [], [message]);

    const {
        globalFilter,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: {pageIndex, pageSize},
        setGlobalFilter,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            initialState: {pageIndex: 0, pageSize: 10, globalFilter: 'unidentified'}, // Initial pagination settings
        },
        useFilters,
        useGlobalFilter,
        usePagination // Adding pagination functionality
    );


    //const { globalFilter } = state;

    // Add delete confirmation
    const deletePhoto = async (photo_id) => {
        if (window.confirm(`Kas oled kindel, et soovid foto (id: ${photo_id}) kustutada?`)) {
            try {
                const token = localStorage.getItem('token');

                const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/deletePhoto/${photo_id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token ? `Bearer ${token}` : '',
                    },
                });

                if (response.redirected) {
                    // Handle redirects, navigate to response.url
                    //window.location.href = response.url;
                    setAuthenticated(false);
                }

                const data = await response.json();

                if (data.success) {
                    setMessageFromDelete(data.message);
                    // When authenticated, setAuthenticated(true);
                    setAuthenticated(true);
                    setLoading(false); // Hide loader when fetching ends
                    // Refresh The Table
                    fetchPhotosRoute();
                } else {
                    setMessageFromDelete(data.message);
                    setAuthenticated(false);
                    setLoading(false); // Hide loader when fetching ends
                    // Refresh The Table
                    fetchPhotosRoute();
                }
            } catch (error) {
                setError(true);
                setMessageFromDelete(error.message);
            }
        }
    };

    return (
        <>
            <TopNav/>
            <AdminRoute/>
            <div className="row">
                <div className="large-12 columns">
                    <div className="panel" style={{width: 'fit-content', minWidth: '100%'}}>
                        {authenticated ? (
                            <>
                        <h3>Haldus</h3>
                        <br/>

                                <div className="panel">
                                    <div align="center">

                                        <div className="row">
                                            <div className="large-12 columns">
                                                <h3>Fotod</h3>
                                                {/*Message from Identify*/}
                                                {messageFromIdentify && (
                                                    <p>{messageFromIdentify}</p>
                                                )}
                                                {/*Message from photoDelete*/}
                                                {messageFromDelete && (
                                                    <p>{messageFromDelete}</p>
                                                )}
                                                {/* Search input for global filtering */}
                                                <input id="table_filter_text"
                                                       type="text"
                                                       value={globalFilter}
                                                       onChange={(e) => setGlobalFilter(e.target.value)}
                                                       placeholder="Filtreeri... Vaikimisi 'unidentified'"
                                                />
                                                <button onClick={() => {
                                                    setGlobalFilter(''); // Clear the global filter state
                                                    document.getElementById('table_filter_text').value = ''; // Clear the input value
                                                }}>
                                                    Tühjenda filter
                                                </button>
                                                {/* Table */}
                                                <table {...getTableProps()} className="table"
                                                       style={{
                                                           width: '100%',
                                                           borderCollapse: 'collapse',
                                                           overflowX: 'auto'
                                                       }}>
                                                    <thead>
                                                    {headerGroups.map((headerGroup) => (
                                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                                            {headerGroup.headers.map((column) => (
                                                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                    </thead>
                                                    {loading ? (
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan="10000">Laen andmeid...</td>
                                                        </tr>
                                                        </tbody>
                                                    ) : (
                                                        <tbody {...getTableBodyProps()}>
                                                        {page.map((row) => {
                                                            prepareRow(row);
                                                            return (
                                                                <tr {...row.getRowProps()}>
                                                                    {row.cells.map((cell) => {
                                                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                                                    })}
                                                                </tr>
                                                            );
                                                        })}
                                                        </tbody>
                                                    )}
                                                </table>
                                                {/* Pagination */}
                                                <div style={{display: 'inline-flex', alignItems: 'baseline'}}>
                                                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                                        {'<<'}
                                                    </button>
                                                    {' '}
                                                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                        {'<'}
                                                    </button>
                                                    {' '}
                                                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                                                        {'>'}
                                                    </button>
                                                    {' '}
                                                    <button onClick={() => gotoPage(pageCount - 1)}
                                                            disabled={!canNextPage}>
                                                        {'>>'}
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    {' '}
                                                    <span>
                                            Leht{' '}
                                                        <strong>
                                              {pageIndex + 1} / {pageOptions.length}
                                            </strong>{' '}
                                        </span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <span style={{display: 'contents'}}>
                                        Mine lehele:
                                                        &nbsp;
                                                        <input
                                                            type="number"
                                                            defaultValue={pageIndex + 1}
                                                            onChange={e => {
                                                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                                                gotoPage(page);
                                                            }}
                                                            style={{width: '100px'}}
                                                        />
                                            </span>{' '}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <select
                                                        style={{width: 'fit-content'}}
                                                        value={pageSize}
                                                        onChange={(e) => setPageSize(Number(e.target.value))}
                                                    >
                                                        {[10, 25, 50, 100, 500].map((pageSize) => (
                                                            <option key={pageSize} value={pageSize}>
                                                                Näita {pageSize}
                                                            </option>
                                                        ))}
                                                        {/*// Add all option into the select*/}
                                                        <option value={data.length}>Näita Kõik NB! Aeglane</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a onClick={() => navigate(-1)}>&lt; Tagasi</a>
                                </div>
                            </>
                        ) : (
                            <>
                            <h2>Haldus</h2>
                            <p><Link to="/login">Logi sisse</Link></p>
                        </>
                        )
                        }

                    </div>
                </div>
            </div>

        </>
    );
}

export default Photos;