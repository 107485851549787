import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {useAuth} from './AuthContext';
import AdminRoute from "./AdminRoute";

function TopNav() {
    const performSearch = () => {
        const searchval = document.getElementById('search_text').value;
        window.location.href = '/search?s=' + searchval;
    };


    const {authenticated, handleLogout} = useAuth();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const menuRef = useRef();
    const [message, setMessage] = useState([]);
    const [noOfUnidentifiedPhotos, setNoOfUnidentifiedPhotos] = useState(null);

    useEffect(() => {
        noOfUnidentifiedPhotosRoute();
    }, [noOfUnidentifiedPhotos]);

    // Closes the dropdown menu when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target) && showMobileMenu) {
                setShowMobileMenu(false); // Close the menu directly
            }
        };

        // Attach the listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Clean up the listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showMobileMenu]);

    // Closes the dropdown menu when scrolling
    useEffect(() => {
        const handleScroll = () => {
            if (showMobileMenu) {
                toggleMobileMenu();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showMobileMenu]);


    // Fetches the number of unidentified photos
    const noOfUnidentifiedPhotosRoute = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/index.php`);
            if (response.ok) {
                const token = localStorage.getItem('token');
                const response = await fetch(`${process.env.REACT_APP_API_URL}/noOfUnidentifiedPhotos`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token ? `Bearer ${token}` : '',
                    },
                });

                const data = await response.json();

                if (data.success) {
                    setMessage(data.message);
                    setNoOfUnidentifiedPhotos(data.message[0]?.noOfUnidentifiedPhotos);
                } else {
                    setMessage(data.message);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const toggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
        // console.log('Mobile menu: ' + !showMobileMenu);
    };

    const handleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const dropdownStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: 'max-content',
        position: 'absolute',
        zIndex: '1',
    };

    const unidentifiedNumberStyle = {
        position: 'absolute',
        top: '0',
        right: '0',
        backgroundColor: 'red',
        color: 'white',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        textAlign: 'center',
        fontSize: '12px',
        lineHeight: '20px',
        margin: '0',
        padding: '0',
    }


    const menuItems = [
            <React.Fragment key="menuItems">
                <li className="has-form">
                    <div className="row collapse">
                        <div className="large-8 small-9 columns">
                            <input type="text" id="search_text" placeholder="Otsi..."
                                   onKeyDown={(event) => {
                                       if (event.keyCode === 13) document.getElementById('btnSearch').click();
                                   }}/>
                        </div>
                        <div className="large-4 small-3 columns">
                            <a href="#" onClick={performSearch} className="alert button expand"
                               id="btnSearch">Otsi</a>
                        </div>
                    </div>
                </li>
                <li className="active"><Link to="/">Esileht</Link></li>
                {/*<li className="active"><Link to="/keys">Määraja</Link></li>*/}
                <li className="active"><Link to="/kalaaju">KalaAju</Link></li>
                <li className="active"><Link to="/dict">Mõisted</Link></li>
                <li className="active"><Link to="/info">Info</Link></li>
                {authenticated && (
                    <>
                        {/*Dropdown menu*/}
                        {!showMobileMenu && (
                            <>
                                <li className="active has-dropdown"
                                    onMouseEnter={() => handleDropdown()}
                                    onMouseLeave={() => handleDropdown()}
                                ><Link to="/admin">Haldus</Link>
                                    {noOfUnidentifiedPhotos &&
                                        <Link to="/admin/photos">
                                            <p title="Määramata fotosid"
                                               style={unidentifiedNumberStyle}>{noOfUnidentifiedPhotos}</p>
                                        </Link>
                                    }
                                    {showDropdown && (
                                        <ul className="" style={dropdownStyle}>
                                            <li className="active"><Link to="/admin/photos">Fotod</Link></li>
                                            <li className="active"><Link to="/listAll">Vaata kõiki
                                                liike</Link>
                                            </li>
                                            <li className="active"><Link to="/admin/logs">Logid</Link></li>
                                        </ul>
                                    )}
                                </li>
                            </>)}

                        {showMobileMenu && (
                            <>
                                <li className="divider"></li>
                                <li className="active"><Link to="/admin">Haldus</Link></li>
                                {/*When mobile menu is open, then items in dropdown menu are rendered*/}
                                <li className="active"><Link to="/admin/photos">Fotod</Link></li>
                                <li className="active"><Link to="/listAll">Vaata kõiki
                                    liike</Link></li>
                                <li className="active"><Link to="/admin/logs">Logid</Link></li>
                                <li className="divider"></li>
                            </>
                        )}

                        <li className="active">
                            <Link onClick={handleLogout}>Logi välja</Link>
                        </li>
                    </>
                )}
            </React.Fragment>
        ]
    ;

    return (
        <>
            <AdminRoute/>
            <div className="contain-to-grid">
                <nav className="top-bar" data-topbar role="navigation">
                    <ul className="title-area">
                        <li className="name">
                            <h1><Link to="/">Eesti kalad ja KalaAju</Link></h1>
                        </li>
                        <li className="toggle-topbar menu-icon">
                            <Link onClick={toggleMobileMenu}>
                                <span>Menüü</span>
                            </Link>
                        </li>
                    </ul>

                    <section className="top-bar-section">
                        {showMobileMenu ? (
                            <ul className="mobile-menu" ref={menuRef}>
                                {menuItems}
                            </ul>
                        ) : (
                            <ul className="right desktop-menu">
                                {menuItems}
                            </ul>
                        )}
                    </section>
                </nav>
            </div>
        </>
    );
}

export default TopNav;
