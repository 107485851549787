import React, {useEffect, useMemo, useState} from 'react';
import TopNav from "./TopNav";
import {useGlobalFilter, usePagination, useTable} from "react-table";
import {useAuth} from "./AuthContext";
import {Link, useNavigate } from "react-router-dom";
import AdminRoute from "./AdminRoute";

const Logs = () => {

    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { authenticated, setAuthenticated, handleLogout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Eesti kalad | Logid';
        setLoading(true);
        {authenticated && fetchLogs()};
    }, [authenticated]);


    const fetchLogs = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/logs`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '',
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            if (response.redirected) {
                // Handle redirects, navigate to response.url
                //window.location.href = response.url;
                setAuthenticated(false);
            }

            const data = await response.json();

            if (data.success) {
                setLogs(Array.isArray(data.message) ? data.message : []);
                setAuthenticated(true);
                // Add loaading
                setLoading(false);
                // Scroll to top to see the message
                window.scrollTo(0, 0);
            } else {
                // Ensure that table always gets an array for data
                setLogs(Array.isArray(data.message) ? data.message : []);
                setAuthenticated(false);
                setLoading(false);
                // Scroll to top to see the message
                window.scrollTo(0, 0);
            }

        } catch (error) {
            //console.error('Error fetching logs:', error);
            setError(true);
            setLogs(error.message);
            setAuthenticated(false);
            setLoading(false);
        }
    };

    const data = useMemo(() => logs, [logs]);

    const columns = useMemo(
        () => [
            {Header: 'ID', accessor: 'log_id'},
            {Header: 'Sündmus', accessor: 'event'},
            {Header: 'Aeg', accessor: 'log_time'},
            {Header: 'Detailid', accessor: 'details'},
            {Header: 'Tagasiside', accessor: 'feedback'},
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize},
        preGlobalFilteredRows,
        setGlobalFilter,
        state,
    } = useTable({
            columns,
            data,
            initialState: {pageIndex: 0, pageSize: 10}, // Initial pagination settings
        },
        useGlobalFilter,
        usePagination);

    return (
        <>
            <TopNav/>
            <AdminRoute/>
            <div className="row">
                <div className="large-12 columns">
                    <div className="panel" style={{fontSize: '100%', width: 'fit-content', minWidth: '100%'}}>
                        {authenticated ? (
                            <>
                                <h3>Haldus</h3>
                                <br/>

                                <div className="panel">
                                    <div align="center">

                                        <div className="row">
                                            <div className="large-12 columns">
                                                <h3>Logid</h3>
                                                <br/>
                                                {/* Global Filter */}
                                                <input
                                                    value={state.globalFilter || ''}
                                                    onChange={e => setGlobalFilter(e.target.value)}
                                                    placeholder={`Filtreeri...`}
                                                />
                                                <button onClick={() => setGlobalFilter('')}>Tühjenda filter</button>
                                                <table {...getTableProps()} className="table"
                                                       style={{
                                                           width: '100%',
                                                           borderCollapse: 'collapse',
                                                           overflowX: 'auto'
                                                       }}>
                                                    <thead>
                                                    {headerGroups.map(headerGroup => (
                                                        <tr {...headerGroup.getHeaderGroupProps()} >
                                                            {headerGroup.headers.map(column => (
                                                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                    </thead>
                                                    {loading ? (
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan="10000">Laen andmeid...</td>
                                                        </tr>
                                                        </tbody>
                                                    ) : (
                                                        <tbody {...getTableBodyProps()}>
                                                        {page.map(row => {
                                                            prepareRow(row);
                                                            return (
                                                                <tr {...row.getRowProps()}>
                                                                    {row.cells.map(cell => {
                                                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                                                    })}
                                                                </tr>
                                                            );
                                                        })}
                                                        </tbody>
                                                    )}
                                                </table>
                                                {/* Pagination */}
                                                <div style={{display: 'inline-flex', alignItems: 'baseline'}}>
                                                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                                        {'<<'}
                                                    </button>
                                                    {' '}
                                                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                        {'<'}
                                                    </button>
                                                    {' '}
                                                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                                                        {'>'}
                                                    </button>
                                                    {' '}
                                                    <button onClick={() => gotoPage(pageCount - 1)}
                                                            disabled={!canNextPage}>
                                                        {'>>'}
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    {' '}
                                                    <span>

                                Leht{' '}
                                                        <strong>
                                    {pageIndex + 1} / {pageOptions.length}
                                </strong>{' '}
                            </span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <span style={{display: 'contents'}}>
                                Mine lehele:
                                                        &nbsp;
                                                        <input
                                                            type="number"
                                                            defaultValue={pageIndex + 1}
                                                            onChange={e => {
                                                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                                                gotoPage(page);
                                                            }}
                                                            style={{width: '100px'}}
                                                        />
                            </span>{' '}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <select
                                                        style={{width: 'fit-content'}}
                                                        value={pageSize}
                                                        onChange={(e) => setPageSize(Number(e.target.value))}
                                                    >
                                                        {[10, 25, 50, 100, 500].map((pageSize) => (
                                                            <option key={pageSize} value={pageSize}>
                                                                Näita {pageSize}
                                                            </option>
                                                        ))}
                                                        {/*// Add all option into the select*/}
                                                        <option value={data.length}>Näita Kõik NB! Aeglane</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a onClick={() => navigate(-1)}>&lt; Tagasi</a>
                                </div>
                            </>
                        ) : (
                            <>
                                <h2>Haldus</h2>
                                <p><Link to="/login">Logi sisse</Link></p>
                            </>
                        )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Logs;