import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import { useAuth } from './AuthContext';
import TopNav from "./TopNav";

const Admin = () => {
    const [message, setMessage] = useState([]);
    const [error, setError] = useState(false);
    const { authenticated, setAuthenticated, handleLogout } = useAuth();

    useEffect(() => {
        document.title = 'Eesti kalad | Haldus';
        fetchAdminRoute();
    }, []);

    const fetchAdminRoute = async () => {
        try {
            const token = localStorage.getItem('token');

            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '',
                },
            });

            if (response.redirected) {
                // Handle redirects, navigate to response.url
                //window.location.href = response.url;
                setAuthenticated(false);
            }

            const data = await response.json();

            if (data.success) {
                setMessage(data.message);
                // When authenticated, setAuthenticated(true);
                setAuthenticated(true);
            } else {
                setMessage(data.message);
                setAuthenticated(false);
            }


        } catch (error) {
            setError(true);
            setMessage(error.message);
        }
    };

    return (
        <div>
        <TopNav/>
        <div className="row">
            <div className="large-12 columns">
                <div className="panel" style={{fontSize: '100%'}}>
                    {authenticated ? (
                        <>
                            {message.length > 0 && (
                                <h3>Haldus - kasutaja "{message[0]?.username}"</h3>
                            )}

                            <button onClick={handleLogout}>Logi välja</button>

                            <div align="center" id="userInfo">
                                {message.length > 0 && (
                                    <>
                                    <p><b>Kasutajanimi:</b> <span id="username">{message[0]?.username}</span></p>
                                    <p><b>Nimi:</b> <span id="name">{message[0]?.name}</span></p>
                                    <p><b>E-mail:</b> <span id="email">{message[0]?.email}</span></p>
                                    <p><b>Roll:</b> <span id="role">{message[0]?.role}</span></p>
                                    </>
                                )}

                                {/* Toolbox section*/}
                                <div id="toolbox" className="toolbox" align="left">
                                    <hr/>
                                    <h4>Tööriistad</h4>
                                    {message.length > 0 && (
                                        <>
                                            <p>Määramata fotosid: {message[0]?.noOfUnidentifiedPhotos}</p>
                                            <p>Määratud fotosid: {message[0]?.noOfIdentifiedPhotos}</p>
                                        </>
                                    )}
                                    <Link to="/admin/adduser" className="button">Lisa kasutaja</Link>
                                    <Link to={`/admin/changePassword/${message[0]?.username}`} className="button">Muuda parooli</Link>
                                    <Link to="/admin/photos" className="button">Fotod</Link>
                                    <Link to="/listall" className="button">Vaata kõiki liike</Link>
                                    <Link to="/admin/logs" className="button">Logid</Link>
                                </div>
                            </div>

                        </>
                    ) : (
                        <>
                            <h2>Haldus</h2>
                            <p><Link to="/login">Logi sisse</Link></p>
                        </>
                    )
                    }

                </div>
            </div>
        </div>

    </div>
    );
};

export default Admin;
