import React, {useEffect} from 'react';
import TopNav from "./TopNav";
import AdminRoute from './AdminRoute';

const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = 'Eesti kalad | Privaatsuspoliitika';
    }, []);

      return (
            <>
            <TopNav />
            <AdminRoute />
            <div className="row">
                <div className="large-12 columns">
                    <div className="panel">
                        <h3>Privaatsuspoliitika</h3>
                        <br/>
                        <p>K&auml;esoleva m&auml;&auml;raja abil on v&otilde;imalik tutvuda k&otilde;igi Eesti vetest
                            p&uuml;&uuml;tud kaladega ja k&auml;sitletud on ka liike,
                            keda veel Eesti aladelt saadud pole, kuid kes meie l&auml;hipiirkonnas on levinud. Rakendus
                            sisaldab dihhotoomset m&auml;&auml;rajat.</p>
                        <p>V&auml;ljaandja: Kalanduse teabekeskus, 2014</p>
                        <p>Rakendus "Eesti kalad" mobiilirakendus ei salvesta ega kogu kasutajate personaalset
                            informatsiooni ega asukohta. Rakendus ei vaja töötamiseks internetivõrku peale
                            selle allalaadimist rakenduste poest. Mobiilirakendus kasutab staatilist teksti ja
                            multimeediat, mida ei uuendata taustal kasutaja teadmata. Kõik sisuuuendused
                            toimuvad läbi rakenduse poe. Mobiilirakenduse manuaalse või automaatse uuendamise saab iga
                            kasutaja ise rakenduse poe sätetest valida.</p>
                        <p>Küsimuste korral kirjutage palun e-posti aadressil: kalateave(att)ut.ee</p>
                        <p>Privaatsuspoliitika informatsioon alates 01.01.2015</p>
                        <br/><br/>
                    </div>
                    <div className="panel">
                        <h3>Privacy policy</h3>
                        <br/>
                        <p>Mobile application "Estonian fishes" is the key to determine different fishes living in Estonia and nearby areas.</p>
                        <p>Publisher: Fisheries Information Centre (Estonia), 2014</p>
                        <p>The application "Estonian fishes" does not record or capture users' personal data and does not collect location information. Application does not need internet access
                            after the application has been downloaded from app store. Only static text and images are used. All the content updates are through the app store. The automatic
                            or manual updating of the mobile applications can be controlled via app store settings of the device.</p>
                        <p>In case of any questions, please write: kalateave(att)ut.ee</p>
                        <p>Privacy policy status since 01.01.2015</p>
                        <br/><br/>
                    </div>
                </div>
            </div>

            </>
        );
    }

export default PrivacyPolicy;
