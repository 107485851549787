import React, {useEffect, useState} from 'react';
import TopNav from "./TopNav";
import AdminRoute from './AdminRoute';

const Search = () => {
    const [estList, setEstList] = useState([]);
    const [title, setTitle] = useState('Leitud liigid');
    const [images, setImages] = useState(null);
    const [dicts, setDicts] = useState([]);

    const queryParams = new URLSearchParams(window.location.search);
    const searchKeyword = queryParams.get('s');

    const dictImageProvided = [
        "ahik",
        "alal6uahaak",
        "kidekile",
        "kyljejoon",
        "liitunud_uimed1_ja_kiirtega_uimed",
        "ninamik",
        "ogakiired",
        "pois",
        "profiil_erih6lmne",
        "profiil_k6huuim1",
        "profiil_p2rakuuim1",
        "profiil_rasvauim",
        "profiil_rinnauim1",
        "profiil_sabauim",
        "profiil_seljauim",
        "seljauime_pikkus",
        "suu_seisud_alaseisune",
        "suu_seisud_otseseisune",
        "suu_seisud_poolalaseisune",
        "suu_seisud_ylaeseisune",
        "suur_l",
        "uime_v2lisserv",
        "uimekiired",
        "v2ike_l"
    ];


    const fetchData = async () => {
        try {
            const responseSpecies = await fetch(`${process.env.REACT_APP_API_URL}/species`);
            const dataSpecies = await responseSpecies.json();
            setEstList(dataSpecies);
            setTitle('Otsingutulemused');

            const responseImages = await fetch(`${process.env.REACT_APP_API_URL}/images`);
            const dataImages = await responseImages.json();
            setImages(dataImages);

            const responseDict = await fetch(`${process.env.REACT_APP_API_URL}/dict`);
            const dataDict = await responseDict.json();
            setDicts(dataDict);
        } catch (error) {
            console.error('Error:', error);
        }
    };


    useEffect(() => {
        fetchData();
        document.title = 'Eesti kalad | Nimekiri';
    }, []);


    const imageMap = {};
    // Check if images is not null before iterating
    if (images && Array.isArray(images)) {
        images.forEach((image) => {
            const imageSrc = `img/t/${image.imageSrc}_t.png`;
            imageMap[image.fid_estfish_id] = imageSrc;
        });
    }

    const renderSearchList = () => {
        return (
            <>
                {searchKeyword.length <= 3 ? (
                    <p>Otsingusõna peab olema vähemalt 4 tähemärki.</p>
                ) : (
                    <>
                        {estList?.length > 0 && searchKeyword.length > 3 && (
                            <>
                                <p>Liikidest: </p>
                                <ul className="list">
                                    {estList.map((fish) => {
                                        if (fish.species_id < 104) {
                                            const matchingImageSrc = imageMap[fish.fid_estfish_id];

                                            if (
                                                matchingImageSrc &&
                                                ((fish.estName.toLowerCase().includes(searchKeyword.toLowerCase())) ||
                                                    (fish.latinName.toLowerCase().includes(searchKeyword.toLowerCase())))
                                            ) {
                                                return (
                                                    <li key={fish.fid_estfish_id} className="mylist">
                                                        <a href={`Description?id=${fish.species_id}`}>
                                                            <img
                                                                onContextMenu={(e) => e.preventDefault()}
                                                                width="100px"
                                                                src={matchingImageSrc}
                                                                alt={`${fish.estName}`}
                                                            />
                                                            <span className="listtext">
                                                            {fish.estName}, <i>{fish.latinName}</i>
                                                        </span>
                                                        </a>
                                                    </li>
                                                );
                                            }
                                        }
                                    })}
                                    {estList.every((fish) => {
                                        const matchingImageSrc = imageMap[fish.fid_estfish_id];
                                        return !(
                                            matchingImageSrc &&
                                            ((fish.estName.toLowerCase().includes(searchKeyword.toLowerCase())) ||
                                                (fish.latinName.toLowerCase().includes(searchKeyword.toLowerCase())))
                                        );
                                    }) && <li className="mylist"> Ei leitud </li>}
                                </ul>
                            </>
                        )}

                        {estList.length === 0 && (<p>Liikidest laen tulemusi.</p>)}
                    </>
                )}
            </>
        );
    };


    const renderSearchDicts = () => {
        return (
            <>
                {dicts.length > 0 && searchKeyword && searchKeyword.length > 3 && (
                    <>
                        <br/>
                        <p>Mõistetest:</p>
                        <ul className="list">
                            {dicts
                                .filter(
                                    (dict) =>
                                        dict.estName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
                                        dict.explanation.toLowerCase().includes(searchKeyword.toLowerCase())                                )
                                .map((dict, index) => (
                                    <li key={index} className="mylist">
                                    <span className="name" style={{fontWeight: 'bold'}}> {dict.estName}
                                    </span>
                                        <div className="desc" style={{fontSize: '14px'}}>
                                            {dict.explanation}
                                            {dictImageProvided.includes(dict.img) && (
                                                <>
                                                    <br/>
                                                    <img src={`img/dict/${dict.img}.jpg`} style={{height: '175px'}}
                                                         alt={{searchKeyword}}/>
                                                </>
                                            )}
                                            <br/>
                                        </div>
                                    </li>
                                ))}
                            {dicts.every((dict) => {
                                return !(
                                    dict.estName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
                                    dict.explanation.toLowerCase().includes(searchKeyword.toLowerCase())
                                );
                            }) && <li className="mylist"> Ei leitud </li>}
                        </ul>
                    </>
                )}

                {dicts.length === 0 && (
                    <p>Mõistetest laen tulemusi.</p>
                )}
            </>
        );
    };

    return (
        <>
            <TopNav/>
            <AdminRoute/>
            <div className="row">
                <div className="large-12 columns">
                    <div className="panel">
                        <div>
                            <h3 dangerouslySetInnerHTML={{__html: title}}></h3>
                            {renderSearchList()}
                            {renderSearchDicts()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Search;