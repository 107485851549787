const keysData = [
    {
        "species": [
            1,
            2,
            3
        ],
        "desc": "Keha ussilaadne, paljas. Puuduvad lõualuud, lõpusekaaned. Suuava ümber sõõrjas sarvhammastega lehter. Paaritu ninaava. Klass: silmud.",
        "extra": "",
        "key_id": 1,
        "parent_key": 0,
        "child_key": 11
    },
    {
        "species": [
            4,
            4100,
            4200,
            4300,
            20,
            5,
            6,
            7,
            8,
            201,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32,
            33,
            34,
            35,
            36,
            37,
            38,
            39,
            40,
            41,
            412,
            413,
            42,
            43,
            44,
            45,
            451,
            46,
            91,
            92,
            93,
            9,
            10,
            11,
            12,
            13,
            14,
            141,
            15,
            151,
            16,
            19,
            17,
            18,
            48,
            49,
            491,
            50,
            51,
            401,
            402,
            47,
            52,
            53,
            54,
            55,
            56,
            78,
            79,
            80,
            81,
            811,
            72,
            722,
            73,
            74,
            75,
            76,
            77,
            777,
            57,
            571,
            58,
            59,
            591,
            60,
            61,
            62,
            63,
            64,
            65,
            66,
            67,
            68,
            69,
            70,
            71
        ],
        "desc": "Esinevad lõualuud, paarisuimed (va merinõela suguküpsetel isenditel), lõpusekaaned. Toes luustunud ning keha katavad luusoomused. Klass: kiiruimsed",
        "key_id": 2,
        "extra": "",
        "parent_key": 0,
        "child_key": 21
    },
    {
        "species": [
            1
        ],
        "desc": "Arvukad sarvhambad mitme kaarja reana. Suguküpsete isendite keha marmoreeritult kirju. Liik: merisutt.",
        "key_id": 11,
        "extra": "",
        "parent_key": 1,
        "child_key": 0
    },
    {
        "species": [
            2,
            3
        ],
        "desc": "Sarvhambad paiknevad hajusalt, isendid ühevärvilised.",
        "key_id": 12,
        "extra": "",
        "parent_key": 1,
        "child_key": 13
    },
    {
        "species": [
            2
        ],
        "desc": "Suurem, sõrmejämedune siirdevorm. Suguküpsetetel iseenditel tugevad ja teravad hambad. Isaste genitaalnäsa lühike ja tömp. Liik: jõesilm.",
        "key_id": 13,
        "extra": "",
        "parent_key": 11,
        "child_key": 0
    },
    {
        "species": [
            3
        ],
        "desc": "Väiksem pliiatsijämedune paikne vorm. Suguküpsetel isendite hambad nõrgad ja nürid. Isaste genitaalnäsa pikk ja peenike. Liik: ojasilm.",
        "key_id": 14,
        "extra": "",
        "parent_key": 11,
        "child_key": 0
    },
    {
        "species": [
            4,
            4100,
            4200,
            4300,
            20,
            5,
            6,
            7,
            8,
            201,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32,
            33,
            34,
            35,
            36,
            37,
            38,
            39,
            40,
            41,
            412,
            413,
            42,
            43,
            44,
            45,
            451,
            46,
            91,
            92,
            93,
            9,
            10,
            11,
            12,
            13,
            14,
            141,
            15,
            151,
            16,
            19,
            17,
            18,
            48,
            49,
            491,
            50,
            51,
            401,
            402,
            47,
            52,
            53,
            54,
            55,
            56,
            72,
            722,
            73,
            74,
            75,
            76,
            77,
            777,
            57,
            571,
            58,
            59,
            591,
            60,
            61,
            62,
            63,
            64,
            65,
            66,
            67,
            68,
            69,
            70,
            71
        ],
        "desc": "Keha sümmeetriline, silmad mõlemal pea küljel.",
        "key_id": 21,
        "extra": "",
        "parent_key": 2,
        "child_key": 211
    },
    {
        "species": [
            78,
            79,
            80,
            81,
            811
        ],
        "desc": "Keha ebasümmetriline, silmad pea ühel küljel. Selts: lestalised.",
        "key_id": 22,
        "extra": "Lestalised - keha lamenenud (suguküpselt), ebasümmeetriline: mõlemad silmad pigmenteeritud küljel, suu ja paarisuimed ebasümmeetrilised, samuti sisuste ja kolju ehitus, närvide kulg. Selja- ja pärakuuim pikad, esineb ka liitumine sabauimega. Kõhuuimed rinnauimede ees. Põhjakalad, lamavad vasakul või paremal kehaküljel. Vastsed sümmeetrilise kehaga, elavad vabas vees ja ujuvad kaladele tavalises asendis.",
        "parent_key": 2,
        "child_key": 221
    },
    {
        "species": [
            78,
            79,
            80
        ],
        "desc": "Silmad sagedamini paremal küljel. Kõhuuimed sümmeetrilised, kitsaste alustega. Kõhuuimede laius nende pikkusest väiksem. Sugukond: lestlased.",
        "key_id": 221,
        "extra": "Silmad paremal küljel, esineb ka vasakpoolseid. Kõhuuimed sümmeetrilised, kitsa alusega. Põhjakalad, mari on enamikul vees hõljuv, ei sisalda õlitilka. Eestis 3 liiki.",
        "parent_key": 22,
        "child_key": 2211
    },
    {
        "species": [
            81,
            811
        ],
        "desc": "Silmad vasakul küljel. Kõhuuimed ebasümmeetrilised, laiade alustega. Kõhuuimede laius ületab nende pikkuse. Sugukond: kammellased.",
        "key_id": 222,
        "extra": "Suu suur, sümmeetriline, alalõug ulatub ette. Mari hõljuv, ühe õlitilgaga.",
        "parent_key": 22,
        "child_key": 2221
    },
    {
        "species": [
            81
        ],
        "desc": "Keha lame, pealtvaates ümaram kui lestadel. Kehal ja peal paiknevad tihedalt teravad luukühmud. Seljakülg värvunud tumepruuniks, alakülg valge. Liik: kammeljas.",
        "key_id": 2221,
        "extra": "",
        "parent_key": 222,
        "child_key": 0
    },
    {
        "species": [
            811
        ],
        "desc": "Keha lame, kuid piklikum kui harilikul kammeljal. Nahk sile, väikeste soomustega, puuduvad harilikule kammeljale iseloomulikud luukühmud. Seljakülg oliivrohekas, heledamate ja tumedamate laikudega. Liik: sile kammeljas.",
        "key_id": 2222,
        "extra": "",
        "parent_key": 222,
        "child_key": 0
    },
    {
        "species": [
            78,
            80
        ],
        "desc": "Küljejoon sirge või rinnauime kohal kergelt kõverdunud.",
        "key_id": 2211,
        "extra": "",
        "parent_key": 221,
        "child_key": 22111
    },
    {
        "species": [
            79
        ],
        "desc": "Küljejoon rinnauime kohal tugevasti kõrgeneva kaarega. Liik: soomuslest",
        "key_id": 2212,
        "extra": "",
        "parent_key": 221,
        "child_key": 0
    },
    {
        "species": [
            80
        ],
        "desc": "Nahk selja- ja pärakuuime aluste juures, küljejoone alguse kohal peas ja sageli kogu kehal kare. Liik: lest.",
        "key_id": 22111,
        "extra": "",
        "parent_key": 2211,
        "child_key": 0
    },
    {
        "species": [
            78
        ],
        "desc": "Nahk nii peas kui ka kerel sile. Liik: merilest.",
        "key_id": 22112,
        "extra": "",
        "parent_key": 2211,
        "child_key": 0
    },
    {
        "species": [
            20,
            5,
            6,
            7,
            8,
            201,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32,
            33,
            34,
            35,
            36,
            37,
            38,
            39,
            40,
            41,
            412,
            413,
            42,
            43,
            44,
            45,
            451,
            46,
            91,
            92,
            93,
            9,
            10,
            11,
            12,
            13,
            14,
            141,
            15,
            151,
            16,
            19,
            17,
            18,
            48,
            49,
            491,
            50,
            51,
            401,
            402,
            47,
            52,
            53,
            54,
            55,
            56,
            72,
            722,
            73,
            74,
            75,
            76,
            77,
            777,
            57,
            571,
            58,
            59,
            591,
            60,
            61,
            62,
            63,
            64,
            65,
            66,
            67,
            68,
            69,
            70,
            71
        ],
        "desc": "Saba sümmeetriline, keha kaetud soomustega, luunaastutega või on paljas.",
        "key_id": 211,
        "extra": "",
        "parent_key": 21,
        "child_key": 2111
    },
    {
        "species": [
            5,
            6,
            7,
            8,
            201,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32,
            33,
            34,
            35,
            36,
            37,
            38,
            39,
            40,
            41,
            412,
            413,
            42,
            43,
            44,
            45,
            451,
            46,
            91,
            92,
            93,
            9,
            10,
            11,
            12,
            13,
            14,
            141,
            15,
            151,
            16,
            19,
            17,
            18,
            48,
            49,
            491,
            50,
            51,
            401,
            402,
            47,
            52,
            53,
            54,
            72,
            722,
            73,
            74,
            75,
            76,
            77,
            777,
            57,
            571,
            58,
            59,
            591,
            60,
            61,
            62,
            63,
            64,
            65,
            66,
            67,
            68,
            69,
            70,
            71
        ],
        "desc": "Kõhuuimed esinevad.",
        "key_id": 2111,
        "extra": "",
        "parent_key": 211,
        "child_key": 21111
    },
    {
        "species": [
            5,
            6,
            7,
            8,
            201,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32,
            33,
            34,
            35,
            36,
            37,
            38,
            39,
            40,
            41,
            412,
            413,
            42,
            43,
            44,
            45,
            451,
            46,
            91,
            92,
            93,
            9,
            10,
            11,
            12,
            13,
            14,
            141,
            15,
            151,
            16,
            19,
            17,
            18,
            401,
            402,
            47,
            52,
            53,
            54
        ],
        "desc": "Kõhuuimed paiknevad kõhul rinnauimede taga.",
        "key_id": 21111,
        "extra": "",
        "parent_key": 2111,
        "child_key": 211111
    },
    {
        "species": [
            5,
            6,
            7,
            8,
            201,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32,
            33,
            34,
            35,
            36,
            37,
            38,
            39,
            40,
            41,
            412,
            413,
            42,
            43,
            44,
            45,
            451,
            46,
            91,
            92,
            93,
            9,
            10,
            11,
            12,
            13,
            14,
            141,
            15,
            151,
            16,
            19,
            17,
            18,
            401,
            402,
            47
        ],
        "desc": "Kõhuuimed normaalse ehitusega.",
        "key_id": 211111,
        "extra": "",
        "parent_key": 21111,
        "child_key": 2111111
    },
    {
        "species": [
            5,
            6,
            7,
            8,
            91,
            92,
            93,
            9,
            10,
            11,
            12,
            13,
            14,
            141,
            15,
            151,
            16,
            19,
            17,
            18,
            47
        ],
        "desc": "Soomused tsükloidsed, mõnikord peened. Üks seljauim, ilma ogadeta. Kidekile ei kasva kuni ahikuni",
        "key_id": 2111111,
        "extra": "",
        "parent_key": 211111,
        "child_key": 21111111
    },
    {
        "species": [
            5,
            6,
            7,
            8,
            91,
            92,
            93,
            9,
            10,
            11,
            12,
            13,
            14,
            141,
            15,
            151,
            16,
            17,
            18
        ],
        "desc": "Seljauim paikneb selja keskel. Kala lõuad ei moodusta kunagi peenikest nokka. Soomused tsükloidsed, küllalt arvukad, mõnikord väga õrnad, äralangevad. Küljejoon (kui esineb), kulgeb piki külje keskjoont.",
        "key_id": 21111111,
        "extra": "",
        "parent_key": 2111111,
        "child_key": 211111111
    },
    {
        "species": [
            91,
            92,
            93,
            9,
            10,
            11,
            12,
            13,
            14,
            141,
            15,
            151,
            16,
            17,
            18
        ],
        "desc": "Seljauime taga sabavarre alguses on tavaliselt pehme rasvauim. Seljauim paikneb selja keskel, ninamik kiiluna sissetungiv. Küljejoon esineb. Osal selle seltsi esindatajatest esinevad alusmised aksillid. Siirde- või mageveekalad.",
        "key_id": 211111111,
        "extra": "",
        "parent_key": 21111111,
        "child_key": 2111111111
    },
    {
        "species": [
            91,
            92,
            93,
            9,
            10,
            11,
            12,
            13,
            14,
            141,
            15,
            151,
            16
        ],
        "desc": "Küljejoon kulgeb piki kogu keha. Selts: lõhelised.",
        "key_id": 2111111111,
        "extra": "",
        "parent_key": 211111111,
        "child_key": 90000001
    },
    {
        "species": [
            91,
            92,
            93,
            9,
            10,
            11,
            12,
            13,
            14,
            141,
            15,
            151
        ],
        "desc": "Seljauim lühike, selles kokku kõige rohkem 16 kiirt. Mageveekalad ja anadroomsed siirdekalad. Sugukond: lõhilased.",
        "key_id": 90000001,
        "extra": "Esineb rasvauim. Emastel langeb mari munasarjast otse kehaõõnde. Sooltorul on rohkesti püloorilisi ripikuid.",
        "parent_key": 2111111111,
        "child_key": 99000001
    },
    {
        "species": [
            91,
            92,
            93,
            9,
            10,
            11,
            12
        ],
        "desc": "Keha küljed mustade ja punaste täppidega või tumedal foonil heleda kirjaga. Keel hammastatud, samuti ka üla- ja alalõualuud, suulaeluu ja sahkluu. Ülalõualuu ulatub silma tagaservani või veidi kaugemale.",
        "key_id": 99000001,
        "extra": "",
        "parent_key": 90000001,
        "child_key": 99000003
    },
    {
        "species": [
            9,
            91,
            92,
            93,
            10,
            11,
            12
        ],
        "desc": "Sahkluu varrel on hambad. Perekond: lõhi",
        "key_id": 99000003,
        "extra": "",
        "parent_key": 99000001,
        "child_key": 99000005
    },
    {
        "species": [
            9
        ],
        "desc": "Sahkluu laba ei ole hammastatud, varrel 1 rida hambaid. Kõik uimed ilma täppideta. Piki keha, küljejoonest kõrgemal (vähesed sellest allpool) harvalt hajutatud mustad tähnid, sageli X-kujulised. Liik: lõhi.",
        "key_id": 99000005,
        "extra": "",
        "parent_key": 99000003,
        "child_key": 0
    },
    {
        "species": [
            10,
            11,
            12
        ],
        "desc": "Sahkluu labal on hambad.",
        "key_id": 99000006,
        "extra": "",
        "parent_key": 99000003,
        "child_key": 99000007
    },
    {
        "species": [
            12
        ],
        "desc": "Sahkluu pikitelje suunas märgatavalt kõverdunud, vars ühes reas hammastega. Piki keha külge kulgeb lai, rohkem või vähem nähtav roosakas-lillakas vööt. Sabauim noorematel isenditel selge sisselõikega, vanematel tömp, alati mustjalt täpistatud. Liik: vikerforell.",
        "key_id": 99000007,
        "extra": "",
        "parent_key": 99000006,
        "child_key": 0
    },
    {
        "species": [
            10,
            11
        ],
        "desc": "Sahkluu vars enam-vähem sirge või ainult kergesti kõverdunud, ühe või kahe rea hammastega. Lillakas vööt keha külgedel puudub. Keha küljed, eriti küljejoonest kõrgemal, tumedate täppidega üle külvatud. Paigusvormidel võivad ilmneda punased ääriseta või äärisega täpid. Sabauim suguküpsetel isenditel ilma sisselõiketa.",
        "key_id": 99000008,
        "extra": "",
        "parent_key": 99000006,
        "child_key": 99000088
    },
    {
        "species": [
            10
        ],
        "desc": "Suguküpse isendi keha külgedel on ainult mustad või tumepruunid täpid. Anadroomne siirdekala. Liik: meriforell.",
        "key_id": 99000088,
        "extra": "",
        "parent_key": 99000008,
        "child_key": 0
    },
    {
        "species": [
            11
        ],
        "desc": "Mustade täppide kõrval esinevad enamasti ka (v.a. Vanemad isendid) punased täpid, mis on tavaliselt heledalt ümbritsetud (nn silmtäpid). Jõekala. Liik: jõeforell.",
        "key_id": 99000089,
        "extra": "",
        "parent_key": 99000008,
        "child_key": 0
    },
    {
        "species": [
            91,
            92,
            93
        ],
        "desc": "Sahkluu vars on hammasteta, sahkluu labal esinevad tugevad hambad võivad ulatuda varre kohale. Perekond: paalia.",
        "key_id": 99000004,
        "extra": "",
        "parent_key": 99000001,
        "child_key": 99000041
    },
    {
        "species": [
            91
        ],
        "desc": "Lõpusepiide arv lõpuskaarel 23-32. Sabauim välisserv kergelt sissepoole kaardunud. Perekond: arktika paalia.",
        "key_id": 99000041,
        "extra": "",
        "parent_key": 99000004,
        "child_key": 0
    },
    {
        "species": [
            92,
            93
        ],
        "desc": "Lõpusepiide arv lõpuskaarel 14-26. Sabauime välisserv sirge või sügava sisselõikega.",
        "key_id": 99000042,
        "extra": "",
        "parent_key": 99000004,
        "child_key": 99000045
    },
    {
        "species": [
            92
        ],
        "desc": "Lõpusepiide arv lõpuskaarel 14-22. Alakeha uimede servadel kitsas valge ja must ääris. Väga värvikirev, rohekaspruun keha kaetud tihedate heledamate tähnidega, mille seas hõredamalt leidub sinaka rõngaga ümbritsetud punaseid tähne. Kudemisperioodil alakeha ja uimed punaseks värvunud. Sabauime välisserv sirge. Liik: ameerika paalia.",
        "key_id": 99000045,
        "extra": "",
        "parent_key": 99000042,
        "child_key": 0
    },
    {
        "species": [
            93
        ],
        "desc": "Lõpusepiide arv lõpuskaarel 16-26. Keha teiste paaliatega võrreldes pikem ja sihvakam. Selg tumeroheline, küljed rohekashallikad, ohtralt kaetud valkjate või kollakate tähnidega. Kunagi ei esine punaseid või roosasid tähne. Alakeha uimedel võib esineda valge ääris. Sabauim sügava sisselõikega. Liik: hall paalia.",
        "key_id": 99000046,
        "extra": "NB! Kalakasvatustes leidub ka halli ja ameerika paalia hübriide (nn. 'splake'), kel esinevad vahepealsed tunnused ja kelle kehal võib esineda roosakaid tähne.",
        "parent_key": 99000042,
        "child_key": 0
    },
    {
        "species": [
            13,
            14,
            15,
            141
        ],
        "desc": "Keha küljed hõbedased, ilma täppideta. Hambad väikesed või vaevalt tuntavad. Ülalõualuu ei ulatu silma tagaservani.",
        "key_id": 99000002,
        "extra": "",
        "parent_key": 90000001,
        "child_key": 99990101
    },
    {
        "species": [
            151
        ],
        "desc": "Suur suu, viltune. Liik: nelma.",
        "key_id": 99990101,
        "extra": "",
        "parent_key": 99000002,
        "child_key": 0
    },
    {
        "species": [
            13,
            14,
            15,
            141
        ],
        "desc": "Suu väike. Perekond: siig.",
        "key_id": 99990102,
        "extra": "",
        "parent_key": 99000002,
        "child_key": 99000101
    },
    {
        "species": [
            14,
            15,
            141
        ],
        "desc": "Suu ei ole ülaseisune, suu(ava) väike. Alalõug liigestub koljuga eespool silma tagaserva. Väiksemad kalad, kasvavad kuni 60(85) cm pikaks.",
        "key_id": 99000101,
        "extra": "",
        "parent_key": 99990102,
        "child_key": 99000103
    },
    {
        "species": [
            15
        ],
        "desc": "Suu selgelt otseseisune. Ülalõug ulatub silma eesservani või selle taha. Ülalõualuu leucuchthys-tüüpi. Lõpusepiisid 46-68. Liik: peled.",
        "key_id": 99000103,
        "extra": "",
        "parent_key": 99000101,
        "child_key": 0
    },
    {
        "species": [
            14,
            141
        ],
        "desc": "Suu ots- või alaseisune, ülalõualuu lavaretus-tüüpi.",
        "key_id": 99000104,
        "extra": "",
        "parent_key": 99000101,
        "child_key": 99000105
    },
    {
        "species": [
            141
        ],
        "desc": "Suu peaaegu otsseisune. Lõpusepiisid 35-45. Ülalõug ulatub silma eesservani või puupillini. Suurtel isenditel tungib alalõua eesserv veidi esile. Liik: peipsi siig.",
        "key_id": 99000105,
        "extra": "",
        "parent_key": 99000104,
        "child_key": 0
    },
    {
        "species": [
            14
        ],
        "desc": "Suu selgelt alaseisune. Ülalõug lühem, ei ulatu silmani. Ninamik enam-vähem rõhtselt ära lõigatud. Keha suhteliselt sale. Lõpusepiisid (15)18 - 30(34). Liik: merisiig.",
        "key_id": 99000106,
        "extra": "",
        "parent_key": 99000104,
        "child_key": 0
    },
    {
        "species": [
            13
        ],
        "desc": "Suu ülaseisune. Ülalõug ulatub vähemalt silma eesservani, reeglina kaugemale; alalõualuu järsult üles pööratud. Ülalõualuu albula-tüüpi. Lõpusepiisid 34-43. Keha suurim kõrgus moodustab tema pikkusest kuni 21%. Kala pikkus ei ületa meie vetes 22-25cm, harva üle 30cm. Liik: rääbis.",
        "key_id": 99000102,
        "extra": "",
        "parent_key": 99990102,
        "child_key": 0
    },
    {
        "species": [
            16
        ],
        "desc": "Seljauim kõrge ja pikk, selles kokku vähemalt 17 kiirt. Piki seljauime paar rida eredaid täppe. Soomused on tugevalt kinnitunud. Mageveekalad. Sugukond: harjuslased, liik: harjus.",
        "key_id": 90000002,
        "extra": "",
        "parent_key": 2111111111,
        "child_key": 0
    },
    {
        "species": [
            17,
            18
        ],
        "desc": "Küljejoon puudulik. Soomused kergesti äratulevad. Hambad vahelõualuul, ülalõualuul ja alalõua eesosas. Väikesed mageveelised kalad, kes ei väldi ka riimvett, vähese liigid on merelised. Selts: tindilised.",
        "key_id": 2111111112,
        "extra": "Tintlased - Pikliku süstja kehaga väikesed kalad. Seljauim lühike, 7-14 kiirega, paikneb keha keskosas kõhuuime kohal. Esineb ujupõis. Paljudele omane värske kurgi lõhn. Parvekalad.",
        "parent_key": 211111111,
        "child_key": 2111111113
    },
    {
        "species": [
            17
        ],
        "desc": "Poolsiirdekala, tungib mere riimveelistest osadest jõgedesse kudema.Pikkus 11-26 cm. Silma läbimõõt mahub vabalt silma eesserva ja ninamiku tipu vahele. Liik: meritint.",
        "key_id": 2111111113,
        "extra": "",
        "parent_key": 2111111112,
        "child_key": 0
    },
    {
        "species": [
            18
        ],
        "desc": "Järvekala. Pikkus 6-12 (18) cm. Silm suhteliselt suurem, selle läbimõõt ei mahu või mahub silma eesserva ja ninamiku tipu vahele. Liik: peipsi tint.",
        "key_id": 2111111114,
        "extra": "",
        "parent_key": 2111111112,
        "child_key": 0
    },
    {
        "species": [
            5,
            6,
            7,
            8
        ],
        "desc": "Üks seljauim, rasvauim puudub. Küljejoon puudub. Paarisuimede alusel on aksillid. Soomused sageli väga õrnad, äralangevad. Merekalad. Selts: heeringalised.",
        "key_id": 211111112,
        "extra": "",
        "parent_key": 21111111,
        "child_key": 2111111121
    },
    {
        "species": [
            8
        ],
        "desc": "Erineb suure suu ja selle kohale ulatuva ninamikuga. Ülalõualuu ulatub kaugele silma taha. Liik: anšoovis.",
        "key_id": 2111111121,
        "extra": "",
        "parent_key": 211111112,
        "child_key": 0
    },
    {
        "species": [
            5,
            6,
            7
        ],
        "desc": "Suu väiksem, ülalõualuu ulatub emamasti silma ette või alla.",
        "key_id": 2111111122,
        "extra": "",
        "parent_key": 211111112,
        "child_key": 2111191221
    },
    {
        "species": [
            7
        ],
        "desc": "Ninamiku ülaserval lõualuude vahel hästi märgatav mediaanne väljalõige (sälk). Sabauime alusel tugevasti pikenenud soomused, nn alae. Alalõug liigestub koljuga silma taga. Liik: vinträim.",
        "key_id": 2111191221,
        "extra": "",
        "parent_key": 2111111122,
        "child_key": 0
    },
    {
        "species": [
            5,
            6
        ],
        "desc": "Mediaanne väljalõige ülalõuas puudub. Pikenenud soomused sabauime alusel puuduvad. Alalõug liigestub koljuga silma all.",
        "key_id": 2111191222,
        "extra": "",
        "parent_key": 2111111122,
        "child_key": 2111111123
    },
    {
        "species": [
            6
        ],
        "desc": "Kiilusoomused kõhu serval tugevasti arenenud ja teravalt lõppevad. Kõhuuime alus seljauime alguse ees või all. Liik: kilu.",
        "key_id": 2111111123,
        "extra": "",
        "parent_key": 2111191222,
        "child_key": 0
    },
    {
        "species": [
            5
        ],
        "desc": "Kiilusoomused on nõrgalt arenenud ja ei ole nii teravad. Kõhuuime alus seljauime keskkoha all. Liik: räim.",
        "key_id": 2111111124,
        "extra": "",
        "parent_key": 2111191222,
        "child_key": 0
    },
    {
        "species": [
            19,
            47
        ],
        "desc": "Seljauim kohakuti pärakuuimega, ninamik nokakujuline.",
        "key_id": 21111112,
        "extra": "",
        "parent_key": 2111111,
        "child_key": 211111121
    },
    {
        "species": [
            47
        ],
        "desc": "Seljauim madal, paikneb pärakuuime kohal, mõlemad samasuguse kujuga. Lõuad on venitunud teravaks nokaks, mis on varustatud peente hammastega. Keha pikk, külgedelt kergelt kokku surutud. Küljejoon kulgeb väga madalalt, valdavalt kõhu serval. Kidemeid 9-15. Merekalad. Selts: tuulehaugilised, liik: tuulehaug.",
        "key_id": 211111121,
        "extra": "",
        "parent_key": 21111112,
        "child_key": 0
    },
    {
        "species": [
            19
        ],
        "desc": "Seljauim kohakuti pärakuuimega. Ninamik lapergune, pardinokakujuline, esineb küljejoon. Mageveekalad, kes võivad esineda ka rannikumeres. Selts haugilised, liik: haug.",
        "key_id": 211111122,
        "extra": "",
        "parent_key": 21111112,
        "child_key": 0
    },
    {
        "species": [
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32,
            33,
            34,
            35,
            36,
            37,
            38,
            39,
            40,
            41,
            42,
            43,
            44,
            45,
            46,
            401,
            402
        ],
        "desc": "Soomused tsükloidsed või keha paljas. Seljauimes võib olla 1-4 oga, kidekile võib kasvada kuni ahikuni. Selgroo alguses võib esineda Weberi aparaat.",
        "key_id": 2111112,
        "extra": "",
        "parent_key": 211111,
        "child_key": 2111111991
    },
    {
        "species": [
            401,
            402
        ],
        "desc": "Kaks seljauime, esimeses seljauimes on 4 oga. Selts: kefaalilised.",
        "key_id": 2111111991,
        "extra": "",
        "parent_key": 2111112,
        "child_key": 2111111997
    },
    {
        "species": [
            401
        ],
        "desc": "Kalale on iseloomulik paksenenud lai ülahuul, mis on alaservas kaetud väikeste näsadega. Hallikashõbedast värvi keha kaetud suurte soomustega. Liik: pakshuul-heloon e paksuhuuleline hallkefaal.",
        "key_id": 2111111997,
        "extra": "",
        "parent_key": 2111111991,
        "child_key": 0
    },
    {
        "species": [
            402
        ],
        "desc": "Pea lühike ja laiapoolne, ülahuul kitsas, näsad huulel puuduvad. Värvus sinakashõbedane, soomused suured, nähtav küljejoon puudub. Liik: kitsashuul-tintkefaal.",
        "key_id": 2111111998,
        "extra": "",
        "parent_key": 2111111991,
        "child_key": 0
    },
    {
        "species": [
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32,
            33,
            34,
            35,
            36,
            37,
            38,
            39,
            40,
            41,
            42,
            43,
            44,
            45,
            46
        ],
        "desc": "Üks seljauim, seljauimes võib olla 1-2 oga",
        "key_id": 2111111992,
        "extra": "",
        "parent_key": 2111112,
        "child_key": 21111121
    },
    {
        "species": [
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32,
            33,
            34,
            35,
            36,
            37,
            38,
            39,
            40,
            41,
            42,
            43,
            44
        ],
        "desc": "Keha kaetud tsükloidsoomustega või paljas. Pea kiiljas, lõuad hambutud. Alaneeluluudel on neeluhambad, võivad esineda poised. Rinnauimed ilma ogadeta, kidekile kasvanud ahiku külge. Rasvauime ei esine. Selts: karpkalalised.",
        "key_id": 21111121,
        "extra": "",
        "parent_key": 2111111992,
        "child_key": 211111211
    },
    {
        "species": [
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32,
            33,
            34,
            35,
            36,
            37,
            38,
            39,
            40,
            41
        ],
        "desc": "Sgk karplased. Suu ümbruses pole poiseid või neid on kõige enam 4. Ujupõis ei ole suletud luust kapslisse.",
        "key_id": 211111211,
        "extra": "",
        "parent_key": 21111121,
        "child_key": 2111112111
    },
    {
        "species": [
            30,
            31,
            40
        ],
        "desc": "Suu poistega.",
        "key_id": 2111112111,
        "extra": "",
        "parent_key": 211111211,
        "child_key": 2111112113
    },
    {
        "species": [
            30,
            31
        ],
        "desc": "Poiseid 1 paar.",
        "key_id": 2111112113,
        "extra": "",
        "parent_key": 2111112111,
        "child_key": 2111112114
    },
    {
        "species": [
            31
        ],
        "desc": "Suuava alaseisune, soomused selgesti nähtavad. Väiksed kalad (10-22 cm). Alahuul ei ole teritunud, seljauimes pole ogasid, piki keha külgi tumedad täpid; neeluhambad kahes reas. Liik: rünt e liivarull.",
        "key_id": 2111112115,
        "extra": "",
        "parent_key": 2111112113,
        "child_key": 0
    },
    {
        "species": [
            30
        ],
        "desc": "Suuava otseseisune, kõik uimed on ümardunud, soomused paiknevad sügaval nahas. Liik: linask.",
        "key_id": 2111112116,
        "extra": "",
        "parent_key": 2111112113,
        "child_key": 0
    },
    {
        "species": [
            40
        ],
        "desc": "Poiseid 2 paari, suuava otsseisune, seljauim pikk (pehmeid kiiri 17-22). Liik: karpkala.",
        "key_id": 2111112114,
        "extra": "",
        "parent_key": 2111112111,
        "child_key": 0
    },
    {
        "species": [
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            32,
            33,
            34,
            35,
            36,
            37,
            38,
            39,
            41,
            412
        ],
        "desc": "Suu poisteta.",
        "key_id": 2111112112,
        "extra": "",
        "parent_key": 211111211,
        "child_key": 2111112900
    },
    {
        "species": [
            34,
            37,
            341,
            21,
            23,
            24,
            25,
            26,
            28,
            35,
            22,
            27,
            32,
            33,
            36,
            41,
            412,
            29
        ],
        "desc": "Seljauim lühike.",
        "key_id": 2111112900,
        "extra": "",
        "parent_key": 2111112112,
        "child_key": 2111112902
    },
    {
        "species": [
            21,
            23,
            24,
            25,
            26,
            28,
            35,
            22,
            27,
            32,
            33,
            36,
            41,
            412,
            29
        ],
        "desc": "Pärakuuimes on kõige enam 23(24) pehmet kiirt",
        "key_id": 2111112902,
        "extra": "",
        "parent_key": 2111112900,
        "child_key": 2111112910
    },
    {
        "species": [
            21,
            23,
            24,
            25,
            26,
            28,
            35,
            22,
            27,
            32,
            33,
            36,
            41,
            412
        ],
        "desc": "Küljejoon täielik.",
        "key_id": 2111112910,
        "extra": "",
        "parent_key": 2111112902,
        "child_key": 2111112912
    },
    {
        "species": [
            41,
            412
        ],
        "desc": "Silmad paikevad madalal, nende alaserv madalamal kui suunurgad, seeläbi moodustub suur ja esileulatuv laup - kala jätab selilipööratu mulje. Suhteliselt kõrge keha ja suure peaga kala.",
        "key_id": 2111112912,
        "extra": "",
        "parent_key": 2111112910,
        "child_key": 2111119911
    },
    {
        "species": [
            41
        ],
        "desc": "Värvus kollakates toonides, külgedel ebakorrapärased tumedad laigud. (Lõpusepiid liitunud pidevaks võrkjaks lindiks. Neeluhambad ühereased, väga tugevad. Kõhul kurgust pärakuuimeni terav kiil. Küljejoones 109-120 soomust). Liik: kirju pakslaup.",
        "key_id": 2111119911,
        "extra": "",
        "parent_key": 2111112912,
        "child_key": 0
    },
    {
        "species": [
            412
        ],
        "desc": "Selg rohekashall, küljed ja kõht hõbedased. Liik: pakslaup.",
        "key_id": 2111119912,
        "extra": "",
        "parent_key": 2111112912,
        "child_key": 0
    },
    {
        "species": [
            21,
            23,
            24,
            25,
            26,
            28,
            35,
            22,
            27,
            32,
            33,
            36,
            413
        ],
        "desc": "Silmad paiknevad tavaliselt, s.o. kõrgemal. Lõpusepiid ja neeluhambad tavalised.",
        "key_id": 2111112913,
        "extra": "",
        "parent_key": 2111112910,
        "child_key": 2111112914
    },
    {
        "species": [
            36
        ],
        "desc": "Suuava alaseisune ja suulõige hoburaudjas, huuled lihavad. Pärakuuim pikk, selles 17-20 pehmet kiirt. Liik: vimb.",
        "key_id": 2111112914,
        "extra": "",
        "parent_key": 2111112913,
        "child_key": 0
    },
    {
        "species": [
            21,
            23,
            24,
            25,
            26,
            28,
            35,
            22,
            27,
            32,
            33,
            413
        ],
        "desc": "Suuava teisiti - ülaseisune, otseseisune, poolalaseisune või ka alaseisune.",
        "key_id": 2111112915,
        "extra": "",
        "parent_key": 2111112913,
        "child_key": 219999998
    },
    {
        "species": [
            413
        ],
        "desc": "Suuava ülaseisune. Soomustel tume tagaserv. Liik: ebarasboora.",
        "key_id": 219999998,
        "extra": "",
        "parent_key": 2111112915,
        "child_key": 0
    },
    {
        "species": [
            21,
            23,
            24,
            25,
            26,
            28,
            35,
            22,
            27,
            32,
            33
        ],
        "desc": "Suuava otseseisune, poolalaseisune või ka alaseisune.",
        "key_id": 219999999,
        "extra": "",
        "parent_key": 2111112915,
        "child_key": 2111112916
    },
    {
        "species": [
            22,
            27,
            32,
            33
        ],
        "desc": "Kõhuserv kõhuuime taga ahenenud kiiluks.",
        "key_id": 2111112916,
        "extra": "",
        "parent_key": 219999999,
        "child_key": 2111112918
    },
    {
        "species": [
            32,
            33
        ],
        "desc": "Kiil moodustub paremalt ja vasakult kõhu küljelt kokkupuutuvatest soomustest.",
        "key_id": 2111112918,
        "extra": "",
        "parent_key": 2111112916,
        "child_key": 2111112920
    },
    {
        "species": [
            32
        ],
        "desc": "Küljejoon on normaalselt jälgitav. Lõpusepiid pikad, tihedalt paiknevad. Liik: viidikas.",
        "key_id": 2111112920,
        "extra": "",
        "parent_key": 2111112918,
        "child_key": 0
    },
    {
        "species": [
            33
        ],
        "desc": "Küljejoon paikneb kahe pigmentrakkudest triibu vahel. Lõpusepiid lühikesed ja harvad. Liik: tippviidikas.",
        "key_id": 2111112921,
        "extra": "",
        "parent_key": 2111112918,
        "child_key": 0
    },
    {
        "species": [
            22,
            27
        ],
        "desc": "Kiil moodustub katusekivijalt paigutatud soomustest.",
        "key_id": 2111112919,
        "extra": "",
        "parent_key": 2111112916,
        "child_key": 2111112922
    },
    {
        "species": [
            27
        ],
        "desc": "Suuava suur. Ülalõug ulatub silma tahapoole. Liik: tõugjas.",
        "key_id": 2111112922,
        "extra": "",
        "parent_key": 2111112919,
        "child_key": 0
    },
    {
        "species": [
            22
        ],
        "desc": "Suuava väike. Ülalõug ulatub silma eesservani. Liik: roosärg.",
        "key_id": 2111112923,
        "extra": "",
        "parent_key": 2111112919,
        "child_key": 0
    },
    {
        "species": [
            21,
            23,
            24,
            25,
            26,
            28,
            35
        ],
        "desc": "Kõhuserv kõhuuime taga pole ahenenud kiiluks.",
        "key_id": 2111112917,
        "extra": "",
        "parent_key": 219999999,
        "child_key": 2111112930
    },
    {
        "species": [
            28
        ],
        "desc": "Soomused peened, küljejoone reas on neid vähemalt 70. Küljejoon võib mõnikord olla katkenud, on üldiselt nõrgalt jälgitav. Liik: lepamaim.",
        "key_id": 2111112930,
        "extra": "",
        "parent_key": 2111112917,
        "child_key": 0
    },
    {
        "species": [
            21,
            23,
            24,
            25,
            26,
            35
        ],
        "desc": "Soomused on hästi nähtavad, küljejoones on neid vähem kui 65.",
        "key_id": 2111112931,
        "extra": "",
        "parent_key": 2111112917,
        "child_key": 2111112932
    },
    {
        "species": [
            35
        ],
        "desc": "Pärakuuim pikk, selles 19-24 pehmet kiirt. Keha külgedelt lamenenud, kõrge seljaga. Liik: nurg.",
        "key_id": 2111112932,
        "extra": "",
        "parent_key": 2111112931,
        "child_key": 0
    },
    {
        "species": [
            21,
            23,
            24,
            25,
            26
        ],
        "desc": "Pärakuuim lühike, selles kõige enam 11 pehmet kiirt.",
        "key_id": 2111112933,
        "extra": "",
        "parent_key": 2111112931,
        "child_key": 2111112934
    },
    {
        "species": [
            21
        ],
        "desc": "Neeluhambad üherealised, silmad punased, küljejoons kõige rohkem 45 soomust. Liik: särg.",
        "key_id": 2111112934,
        "extra": "",
        "parent_key": 2111112933,
        "child_key": 0
    },
    {
        "species": [
            23,
            24,
            25,
            26
        ],
        "desc": "Neeluhambad kaherealised, silmad kollased, kuldsed või valkjad.",
        "key_id": 2111112935,
        "extra": "",
        "parent_key": 2111112933,
        "child_key": 2111112936
    },
    {
        "species": [
            23
        ],
        "desc": "Selja- ja pärakuuim ümardunud välisservaga. Neeluhambad terava hambuse äärega, mälumispindmikul pikivaoga. Otsmik väga lai. Kõhukelme tumepruun. Liik: valgeamuur.",
        "key_id": 2111112936,
        "extra": "",
        "parent_key": 2111112935,
        "child_key": 0
    },
    {
        "species": [
            24,
            25,
            26
        ],
        "desc": "Selja- ja pörakuuime välisserv ei ole ümardunud või siis ainult pärakuuim selgelt ümardunud (turb). Neeluhambad tavalised, siledad või ainult otstes pisut kurrutatud.",
        "key_id": 2111112937,
        "extra": "",
        "parent_key": 2111112935,
        "child_key": 2111112938
    },
    {
        "species": [
            24,
            25,
            26
        ],
        "desc": "Suuava otseseisune",
        "key_id": 2111112938,
        "extra": "",
        "parent_key": 2111112937,
        "child_key": 2111112940
    },
    {
        "species": [
            26
        ],
        "desc": "Küljejoones 43-48 soomust, pärakuuime välisserv kergelt ümardunud. Liik: turb.",
        "key_id": 2111112940,
        "extra": "",
        "parent_key": 2111112938,
        "child_key": 0
    },
    {
        "species": [
            25
        ],
        "desc": "Küljejoones 53-63 soomust, pärakuuime välisserv kergelt nõgus. Liik: säinas.",
        "key_id": 2111112941,
        "extra": "",
        "parent_key": 2111112938,
        "child_key": 0
    },
    {
        "species": [
            24
        ],
        "desc": "Suuava poolalaseisune kuni alaseisune, küljejoones 47-56 soomust. Liik: teib.",
        "key_id": 2111112939,
        "extra": "",
        "parent_key": 2111112937,
        "child_key": 0
    },
    {
        "species": [
            29
        ],
        "desc": "Küljejoon ebatäielik, nähtav kõige enam 14-nel eesmisel soomusel. Soomused nõrgalt kinnitunud, 40-50 põikirida. Pärakuuimes 10-13 pehmet kiirt. Liik: mudamaim.",
        "key_id": 2111112911,
        "extra": "",
        "parent_key": 2111112902,
        "child_key": 0
    },
    {
        "species": [
            34,
            37,
            341
        ],
        "desc": "Pärakuuimes üle 24 pehme kiire.",
        "key_id": 2111112903,
        "extra": "",
        "parent_key": 2111112900,
        "child_key": 2111112983
    },
    {
        "species": [
            37
        ],
        "desc": "Suuava ülaseisune, küljejoon laineline, keha pikk, selg silmapaistvalt sirge. Liik: nugakala.",
        "key_id": 2111112983,
        "extra": "",
        "parent_key": 2111112903,
        "child_key": 0
    },
    {
        "species": [
            34,
            341
        ],
        "desc": "Suuava poolalaseisune, küljejoon korrapärase kaarena, keha sirge, külgedelt tugevasti lamendunud.",
        "key_id": 2111112984,
        "extra": "",
        "parent_key": 2111112903,
        "child_key": 2111112985
    },
    {
        "species": [
            34
        ],
        "desc": "Pärakuimes 24-30 pehmet, küljejoones 51-60 soomust. Suuava poolalaseisune. Liik: latikas.",
        "key_id": 2111112985,
        "extra": "",
        "parent_key": 2111112984,
        "child_key": 0
    },
    {
        "species": [
            341
        ],
        "desc": "Pärakuuimes 35-44 pehmet kiirt, küljejoones 65-75 soomust. Suuava otseseisune. Liik: abakala.",
        "key_id": 2111112986,
        "extra": "",
        "parent_key": 2111112984,
        "child_key": 0
    },
    {
        "species": [
            38,
            39,
            201
        ],
        "desc": "Seljauim pikk, pehmeid kiiri 7-21, keha kõrge, külgedelt lamenenud, mõnel liigil esineb sabavarrel iseloomulik triip.",
        "key_id": 2111112901,
        "extra": "",
        "parent_key": 2111112112,
        "child_key": 2111112955
    },
    {
        "species": [
            38,
            39
        ],
        "desc": "Seljauim pikk, pehmeid kiiri 14-21. Sabavarrel ei esine sinakat triipu.",
        "key_id": 2111112955,
        "extra": "",
        "parent_key": 2111112901,
        "child_key": 2111112991
    },
    {
        "species": [
            38
        ],
        "desc": "Seljauime välisserv kergelt kumer. Selja- ja pärakuiuime viimane ogakiir sabapoolsel serval peensaagjas. Kõhukelme hele, puudub pigment. Esimesel lõpusekaarel 23-35 piid. Küljejoones 32-35 soomust. Liik: koger.",
        "key_id": 2111112991,
        "extra": "",
        "parent_key": 2111112955,
        "child_key": 0
    },
    {
        "species": [
            39
        ],
        "desc": "Seljauime välisserv sirge või kerge väljalõikega. Selja- ja pärakuuime viimane ogakiir on sabapoolsel serval jämesaagjas. Kõhukelme tume, tugevasti pigmenteeritud. Lõpusepiisid 39-52. Küljejoones 26-31 soomust. Liik: hõbekoger.",
        "key_id": 2111112992,
        "extra": "",
        "parent_key": 2111112955,
        "child_key": 0
    },
    {
        "species": [
            201
        ],
        "desc": "Seljauime pehmeid kiiri 7-11, piki sabavart jookseb iseloomulik sinakas triip. Emaskaladel esineb pikk muneti marjaterade limuste mantliõõnde paigutamiseks. Liik: euroopa mõrukas.",
        "key_id": 2111112956,
        "extra": "",
        "parent_key": 2111112901,
        "child_key": 0
    },
    {
        "species": [
            42,
            43,
            44
        ],
        "desc": "Sgk hinklased. Suud ümbritseb 6-8 poiset. Ujupõie eesosa on suletud luust kapslisse; kalad pikliku kujuga.",
        "key_id": 211111212,
        "extra": "",
        "parent_key": 21111121,
        "child_key": 2111112121
    },
    {
        "species": [
            42,
            44
        ],
        "desc": "Poiseid 6",
        "key_id": 2111112121,
        "extra": "",
        "parent_key": 211111212,
        "child_key": 2111192121
    },
    {
        "species": [
            42
        ],
        "desc": "2. Poised peaaegu võrdse pikkusega. Pea külgedelt lamenenud. Silma all kahetipuline kokkupandav oga, kompamisel hästi tuntav. Liik: hink.",
        "key_id": 2111192121,
        "extra": "",
        "parent_key": 2111112121,
        "child_key": 0
    },
    {
        "species": [
            44
        ],
        "desc": "Poised ebavõrdse pikkusega. Pea ei ole külgedelt lamenenud. Silmaalune terav, kokkupandav oga puudub. Liik: trulling.",
        "key_id": 2111192122,
        "extra": "",
        "parent_key": 2111112121,
        "child_key": 0
    },
    {
        "species": [
            43
        ],
        "desc": "Poiseid 10, neist 4 alalõual. Liik: vingerjas.",
        "key_id": 2111112122,
        "extra": "",
        "parent_key": 211111212,
        "child_key": 0
    },
    {
        "species": [
            45,
            451,
            46
        ],
        "desc": "Keha paljas (v luuplaadikesed). Pea lai, selja-kõhu suunas laienenud. Suuava lai, rõhtne. Lõugadel ridades peened hambad. Esinevad poised, neist üks paar on eriti pikad. Rinnauimes on tugev oga. Kidakile ei ole ahiku külge kasvanud. Selts: sägalised.",
        "key_id": 21111122,
        "extra": "",
        "parent_key": 2111111992,
        "child_key": 211111221
    },
    {
        "species": [
            45
        ],
        "desc": "Poiseid 3 paari - ülalõual üks paar pikki poiseid, alalõual 2 paari lühemaid poiseid. Seljauim väga väike. Liik: säga.",
        "key_id": 211111221,
        "extra": "",
        "parent_key": 21111122,
        "child_key": 0
    },
    {
        "species": [
            451,
            46
        ],
        "desc": "Poiseid vähemalt 4 paari.",
        "key_id": 211111222,
        "extra": "",
        "parent_key": 21111122,
        "child_key": 2111112221
    },
    {
        "species": [
            451
        ],
        "desc": "Üks pikk seljauim, pikk pärakuuim. Liik: suur angersäga.",
        "key_id": 2111112221,
        "extra": "",
        "parent_key": 211111222,
        "child_key": 0
    },
    {
        "species": [
            46
        ],
        "desc": "Kaks seljauime, lühem pärakuuim. Liik: kanalisäga.",
        "key_id": 2111112222,
        "extra": "",
        "parent_key": 211111222,
        "child_key": 0
    },
    {
        "species": [
            52,
            53,
            54
        ],
        "desc": "Kõhuuimed moondunud ogadeks, seljauime ees esinevad eraldiseisvad ogad. Nahk paljas või siis keha külgedel esinevad luukilbikesed. Selts: ogalikulised.",
        "key_id": 211112,
        "extra": "Väikesed kalad, väga kitsa sabaosaga. Kõhuuimedes alati 1 oga.",
        "parent_key": 21111,
        "child_key": 2112211
    },
    {
        "species": [
            52,
            53
        ],
        "desc": "2-12 seljaoga. Vaagnavöö luud on oma siseservadega kokku kasvanud. Kõhuuime ogad toetuvad keha külgede poolt vastu neid luid.",
        "key_id": 2112211,
        "extra": "",
        "parent_key": 211111,
        "child_key": 21122111
    },
    {
        "species": [
            52
        ],
        "desc": "Seljaogasid 2-5, harilikult 3 tugevat. Kidekiled on kasvanud ahiku külge. Liik: ogalik.",
        "key_id": 21122111,
        "extra": "",
        "parent_key": 2112211,
        "child_key": 0
    },
    {
        "species": [
            53
        ],
        "desc": "Seljaogasid 7-12, harilikult 9 lühikest oga. Kidekiled omavahel kokku kasvanud, moodustavad vaba kurru risti üle ahiku. Liik: luukarits.",
        "key_id": 21122112,
        "extra": "",
        "parent_key": 2112211,
        "child_key": 0
    },
    {
        "species": [
            54
        ],
        "desc": "14-17 seljaoga. Vaagnavöö luuud ei ole oma siseservadega kokku kasvanud. Kumbki neist kannab kõhuuime oga. Liik: raudkiisk.",
        "key_id": 2112212,
        "extra": "",
        "parent_key": 211111,
        "child_key": 0
    },
    {
        "species": [
            48,
            49,
            50,
            51,
            57,
            58,
            59,
            60,
            61,
            62,
            63,
            64,
            65,
            66,
            67,
            68,
            69,
            70,
            71,
            72,
            73,
            74,
            75,
            76,
            77
        ],
        "desc": "Kõhuuimed teistsuguse paigutusega, mõnikord omapäraste moodustistena või võivad isegi puududa.",
        "key_id": 21112,
        "extra": "",
        "parent_key": 2111,
        "child_key": 211121
    },
    {
        "species": [
            48,
            49,
            50,
            51
        ],
        "desc": "Kõhuuimed paiknevad kurgu all, 2 või 3 pehmekiirelist seljauime, 1 või 2 pärakuuime. Keha kaetud peente tsükloidsoomustega. Suu ümbruses (lõuatsil) esineb vähemalt üks poise. Selts: tursalised, sugukond tursklased.",
        "key_id": 211121,
        "extra": "",
        "parent_key": 21112,
        "child_key": 2111211
    },
    {
        "species": [
            48,
            49,
            491
        ],
        "desc": "Kolm seljauime, kaks pärakuuime",
        "key_id": 2111211,
        "extra": "",
        "parent_key": 211121,
        "child_key": 21112111
    },
    {
        "species": [
            48,
            491
        ],
        "desc": "Alahuulel on poise.",
        "key_id": 21112111,
        "extra": "",
        "parent_key": 2111211,
        "child_key": 211121111
    },
    {
        "species": [
            48
        ],
        "desc": "Alahuulel on poise, pärakuuimed on peaaegu võrdse pikkusega. Selg pruunikas või hallikas, külgedel ohtralt tumedaid täppe. Liik: tursk.",
        "key_id": 211121111,
        "extra": "",
        "parent_key": 21112111,
        "child_key": 0
    },
    {
        "species": [
            491
        ],
        "desc": "Alahuulel on tilluke poise, pärakuuimed on erineva pikkusega. Küljejoon hele, kulgeb sirgelt. Liik: süsikas e põhjaatlandi süsikas.",
        "key_id": 211121112,
        "extra": "",
        "parent_key": 21112111,
        "child_key": 0
    },
    {
        "species": [
            49
        ],
        "desc": "Poised alahuulel puuduvad. Alalõug ülalõuast pikem. Liik: pollak.",
        "key_id": 21112112,
        "extra": "",
        "parent_key": 2111211,
        "child_key": 0
    },
    {
        "species": [
            50,
            51
        ],
        "desc": "Kaks seljauime, üks pikk pärakuuim. Sabauim ümardunud.",
        "key_id": 2111212,
        "extra": "",
        "parent_key": 211121,
        "child_key": 21112121
    },
    {
        "species": [
            50
        ],
        "desc": "Ainus poise paikneb alahuulel. Seljauimed võrdse kõrgusega, eesmine lühike, tagumine pikk. Liik: luts.",
        "key_id": 21112121,
        "extra": "",
        "parent_key": 2111212,
        "child_key": 0
    },
    {
        "species": [
            51
        ],
        "desc": "Poiseid 4, neist 2 ninaavade ees, üks ninamiku tipul ja üks alahuulel. Eesmine seljauim madal, ainult tema esimene kiir on kõrge, tagumine uim pikk. Liik: neljapoiseluts.",
        "key_id": 21112122,
        "extra": "",
        "parent_key": 2111212,
        "child_key": 0
    },
    {
        "species": [
            57,
            58,
            59,
            60,
            61,
            62,
            63,
            64,
            65,
            66,
            67,
            68,
            69,
            70,
            71,
            72,
            73,
            74,
            75,
            76,
            77
        ],
        "desc": "Kõhuuimed rinna all v kurgul, võivad olla muutunud lehtriks v iminapaks. Jädemelised v puuduvad, mis juhul ulatub alalõug ette v lõuad on venitunud pikaks ja teravaks mõõgaks. 1 v 2 seljauime, milles ogakiiri rohkem kui pehmeid kiiri. Ogade puudumise korral pole uimekiired hargnenud, vaid enamvähem jäigad. Keha kaetud ktenoid-, harvem tsükloidsoomustega v luukilpidega. Palja keha puhul võivad peas olla ogad ja piki külgi karedad mügarad.",
        "key_id": 211122,
        "extra": "",
        "parent_key": 21112,
        "child_key": 2111221
    },
    {
        "species": [
            72,
            73,
            74,
            75,
            76,
            77
        ],
        "desc": "Risti põskedele kulgeb silmaalune tugi - võlvkaar, moodustunud silmaalusest luust, mille tagumine ots on ühendatud eeskaanega. See tugikaar on läbi õhukese põsenaha kergesti nõelaga kombatav. Rinnauimede alus tavaliselt lai. Harilikult paksu, enam-vähem laperguseks litsutud peaga. Selts: meripuugilised.",
        "key_id": 2111221,
        "extra": "",
        "parent_key": 211122,
        "child_key": 21112211
    },
    {
        "species": [
            76,
            77
        ],
        "desc": "Kõhuuimed muutunud iminapaks. Üks või kaks seljauime.",
        "key_id": 21112211,
        "extra": "",
        "parent_key": 2111221,
        "child_key": 211122111
    },
    {
        "species": [
            77
        ],
        "desc": "Ainus seljauim ja pärakuuim pikad, ulatuvad sabauimeni või isegi sellele. Nahk sile, soomusteta ja köprudeta. Keha pikk, külgedelt lamenenud. Sugukond: pullukalalased, liik: pullukala.",
        "key_id": 211122111,
        "extra": "",
        "parent_key": 21112211,
        "child_key": 0
    },
    {
        "species": [
            76
        ],
        "desc": "Seljauimi kaks, neist eesmine atrofeerunud, peitub osaliselt lihavas küürulaadses seljaharjas. Nahk soomusteta, kuid piki keha külgi kulgeb 3 rida luutüükaid. Keha jässakas. Sugukond: merivarblaslased, liik: merivarblane.",
        "key_id": 211122112,
        "extra": "",
        "parent_key": 21112211,
        "child_key": 0
    },
    {
        "species": [
            72,
            73,
            74,
            75,
            722,
            777
        ],
        "desc": "Kõhuuimed pole muutunud iminapaks, seljauimi kaks. Sugukond: võldaslased.",
        "key_id": 21112212,
        "extra": "",
        "parent_key": 2111221,
        "child_key": 211122121
    },
    {
        "species": [
            73,
            74,
            75,
            777
        ],
        "desc": "Peas tugevad paarisogade read või sarvelaadsed kühmud, mis paiknevad nii pea külgedel kui ka ülapoolel. Kõhuuimes 3 või 4 kiirt.",
        "key_id": 211122121,
        "extra": "",
        "parent_key": 21112212,
        "child_key": 2111221211
    },
    {
        "species": [
            73,
            74,
            777
        ],
        "desc": "Kidekiled teineteisega kokku kasvanud, moodustavad ahiku kohal ristkurru. Peale ninaogade ja hästiarenenud silmataguste ogade esineb kõigest üks paar hästi väljaarenenud koonusjaid kuklaogasid.",
        "key_id": 2111221211,
        "extra": "",
        "parent_key": 211122121,
        "child_key": 2111229211
    },
    {
        "species": [
            73,
            777
        ],
        "desc": "Kehal esinevad luuplaadid. Pea väiksem, pikkuselt 30(32) % ja kõrguselt 11-15% keha pikkusest. Rinnauime välispind kare.",
        "key_id": 2111229211,
        "extra": "",
        "parent_key": 2111221211,
        "child_key": 2111229911
    },
    {
        "species": [
            73
        ],
        "desc": "Küljejoon sirge, selle kõrval (eriti ülalpool) on näha 1-2 pikirida ümarjaid luuplaate. Liik: merihärg.",
        "key_id": 2111229911,
        "extra": "",
        "parent_key": 2111229211,
        "child_key": 0
    },
    {
        "species": [
            777
        ],
        "desc": "Pea lai, ogaline. Keha kaetud tugevate luuplaatidega. Pea alumisel küljel asuvad arvukad poised. Liik: rüühärg e euroopa rüühärg.",
        "key_id": 2111229912,
        "extra": "",
        "parent_key": 2111229211,
        "child_key": 0
    },
    {
        "species": [
            74
        ],
        "desc": "Kehal ei esine luuplaate. Pea suurem, pikkuselt vähemalt 32 ja kõrguselt 16-20% keha pikkusest. Rinnauime välispind sile. Küljejoon sabaosas selgelt kõverdunud. Liik: nolgus.",
        "key_id": 2111229212,
        "extra": "",
        "parent_key": 2111221211,
        "child_key": 0
    },
    {
        "species": [
            75
        ],
        "desc": "Kidekiled on kasvanud laia ahiku külge ega moodusta ristkurdu. Väliselt on lõpuseava kitsas. Peale ninaogade ja madalate silmataguste ogade esineb kaks paari pikki kuklaharju, mis lõpevad taha suunatud ogadega. Liik: meripühvel.",
        "key_id": 2111221212,
        "extra": "",
        "parent_key": 211122121,
        "child_key": 0
    },
    {
        "species": [
            72,
            722
        ],
        "desc": "Peas pole ogasid, kuid see võib olla (eriti suurematel isenditel) kaetud pisikeste köbrukestega, mis meenutavad karplaste kudemisaegset helmeskatet. Kõhuuimed viiekiirelised, nende sisemine, äärmine kiir on suhteliselt hästi arenenud, pikkuselt vähemalt pool uime pikkust.",
        "key_id": 211122122,
        "extra": "",
        "parent_key": 21112212,
        "child_key": 2111221221
    },
    {
        "species": [
            72
        ],
        "desc": "Värvus pruunides toonides, tumedamate laikudega. Uimed heledamad. Liik: võldas.",
        "key_id": 2111221221,
        "extra": "",
        "parent_key": 211122122,
        "child_key": 0
    },
    {
        "species": [
            722
        ],
        "desc": "Värvus oliivrohekates või pruunikates toonides. Kõhuuimed kirjatud 5-15 selge ristvöödiga, ulatuvad pärakuni. Alalõua tipu all lähestikuste pooride paar. Liik: kirjuuimeline võldas.",
        "key_id": 2111221222,
        "extra": "",
        "parent_key": 211122122,
        "child_key": 0
    },
    {
        "species": [
            57,
            571,
            58,
            59,
            591,
            60,
            61,
            62,
            63,
            64,
            65,
            66,
            67,
            68,
            69,
            70,
            71
        ],
        "desc": "Silmaalune tugi - võlvkaar - puudub. Rinnauimede alus on enam-vähem vertikaalne, paikneb suhteliselt kõrgel külgedel. Kõhuuimede, kui esinevad, rinnaseisused (torakaalsed), harva subtorsakaalsed või kurguseisused (jugulaarsed). Sabauimes enamasti 12-15 (k 19) hargnenud kiirt. Uimed tavaliselt ogadega. Selts: ahvenalised.",
        "key_id": 2111222,
        "extra": "",
        "parent_key": 211122,
        "child_key": 21112221
    },
    {
        "species": [
            57,
            58,
            59,
            60,
            61,
            62,
            65,
            66,
            67,
            68,
            69,
            70,
            71
        ],
        "desc": "Suuõõs hammastatud. Kui hambad on mandunud, siis lõuad on pikad, neist ülemine terava mõõgana.",
        "key_id": 21112221,
        "extra": "",
        "parent_key": 2111222,
        "child_key": 211122211
    },
    {
        "species": [
            57,
            58,
            59,
            60,
            61,
            62,
            65,
            66,
            67,
            68,
            69
        ],
        "desc": "Ülalõualuu ja eesülalõualuu ei moodusta liikumatut tervikut.",
        "key_id": 211122211,
        "extra": "",
        "parent_key": 21112221,
        "child_key": 2111222111
    },
    {
        "species": [
            65,
            66,
            67,
            68,
            69
        ],
        "desc": "Kõhuuimed kokku kasvanud lehtriks, paiknevad rinna all. Väiksed mere rannikuvöötmes elavad kalad. Sugukond: mudillased.",
        "key_id": 2111222111,
        "extra": "",
        "parent_key": 211122211,
        "child_key": 2115222111
    },
    {
        "species": [
            66,
            67,
            68
        ],
        "desc": "Vahetult silmade all on genipooride horisontaalsed read. Seljauimed paiknevad teineteisest eemal.",
        "key_id": 2115222111,
        "extra": "",
        "parent_key": 2111222111,
        "child_key": 2115222113
    },
    {
        "species": [
            68
        ],
        "desc": "Silmad (nende eestküljed) paigutuvad pea külgedega paralleelselt, vahemaa nende vahel on lai, umbes 80%, silma läbimõõdust. Liik: kirjumudil.",
        "key_id": 2115222113,
        "extra": "",
        "parent_key": 2115222111,
        "child_key": 0
    },
    {
        "species": [
            66,
            67
        ],
        "desc": "Silmad paigutuvad pea külgede suhtes kaldu, vahemaa nende vahel on väike, umbes 33% silma läbmõõdust.",
        "key_id": 2115222114,
        "extra": "",
        "parent_key": 2115222111,
        "child_key": 2115222115
    },
    {
        "species": [
            66
        ],
        "desc": "Kõhuuime ühendava kile (imilehtri membraani) serv kurrutatud. Soomuseid on külje keskjoonel üle 60. Kurgualune ja kaela tagaosa kaetud soomustega. Liik: väike mudilake.",
        "key_id": 2115222115,
        "extra": "",
        "parent_key": 2115222114,
        "child_key": 0
    },
    {
        "species": [
            67
        ],
        "desc": "Kõhuuimi ühendava kile serv sile. Soomuseid külje keskjoonel 43-52. Kurgualune ja kaelatagune soomusteta. Liik: pisimudilake.",
        "key_id": 2115222116,
        "extra": "",
        "parent_key": 2115222114,
        "child_key": 0
    },
    {
        "species": [
            65,
            69
        ],
        "desc": "Vahetult silmade all pole horisontaalset genipooride rida.",
        "key_id": 2115222112,
        "extra": "",
        "parent_key": 2111222111,
        "child_key": 333354541
    },
    {
        "species": [
            65
        ],
        "desc": "Esineb üks horisontaalne genipooride rida põse alaosas. Liik: must mudil.",
        "key_id": 333354541,
        "extra": "",
        "parent_key": 2115222112,
        "child_key": 0
    },
    {
        "species": [
            69
        ],
        "desc": "Horisontaalsed genipooride read põsel puuduvad. Liik: ümarmudil.",
        "key_id": 333354542,
        "extra": "",
        "parent_key": 2115222112,
        "child_key": 0
    },
    {
        "species": [
            57,
            571,
            58,
            59,
            60,
            61,
            62
        ],
        "desc": "Kõhuuimed normaalselt arenenud, manduvalt väiksed või võivad ka puududa.",
        "key_id": 2111222112,
        "extra": "",
        "parent_key": 211122211,
        "child_key": 2115222117
    },
    {
        "species": [
            57,
            571,
            58,
            59,
            591
        ],
        "desc": "Keha ei ole pikenenud. Kõhuuimed normaalselt arenenud. Keha kaetud ktenoidsoomustega. Lõpusekaanel võivad olla ogad. Kõhuuimed ühe ogakiire ja viie pehme kiirega, paiknevad kohakuti rinnauimega. Kaks seljauime, ogakiirte ülekaaluga, ainuke erand koha. Pärakuuimes vähemalt kaks oga. Magevee- ja merekalad.",
        "key_id": 2115222117,
        "extra": "",
        "parent_key": 2111222112,
        "child_key": 335222111
    },
    {
        "species": [
            571
        ],
        "desc": "Lõpuskaanel esineb kaks tugevat tahapoole suunatud oga. Keha värvus hõbedane. Liik: huntahven.",
        "key_id": 335222111,
        "extra": "",
        "parent_key": 2115222117,
        "child_key": 0
    },
    {
        "species": [
            57,
            58,
            59,
            591
        ],
        "desc": "Lõpuskaaned ogadeta või ühe ogaga. Keha värvus kollakas või rohekas.",
        "key_id": 335222112,
        "extra": "",
        "parent_key": 2115222117,
        "child_key": 335222113
    },
    {
        "species": [
            57,
            58,
            59
        ],
        "desc": "Küljejoon esineb. Sugukond: ahvenlased.",
        "key_id": 335222113,
        "extra": "",
        "parent_key": 335222112,
        "child_key": 2115222119
    },
    {
        "species": [
            58
        ],
        "desc": "Küljejoon ulatub sabauimele. Lõpusekaas ilma ogadeta. Harjasetaoliste hammaste vahelt ulatuvad välja suured kihv(rööv)hambad. Liik: koha.",
        "key_id": 2115222119,
        "extra": "",
        "parent_key": 335222113,
        "child_key": 0
    },
    {
        "species": [
            57,
            59
        ],
        "desc": "Küljejoon ei ulatu sabauimele. Lõpusekaas lõpeb terava ogaga. Hambad harjasjad, suured hambad puuduvad.",
        "key_id": 2115222120,
        "extra": "",
        "parent_key": 335222113,
        "child_key": 2115222121
    },
    {
        "species": [
            57
        ],
        "desc": "Seljauimed teineteisest sälguga eraldatud, enam-vähem sama kõrgusega. Liik: ahven.",
        "key_id": 2115222121,
        "extra": "",
        "parent_key": 2115222120,
        "child_key": 0
    },
    {
        "species": [
            59
        ],
        "desc": "Seljauimed on omavahel ühendatud, uime eesosa tagaosast kõrgem. Liik: kiisk.",
        "key_id": 2115222122,
        "extra": "",
        "parent_key": 2115222120,
        "child_key": 0
    },
    {
        "species": [
            591
        ],
        "desc": "Küljejoon puudub. Pea väga suur. Liik: kaugida unimudil.",
        "key_id": 335222114,
        "extra": "",
        "parent_key": 335222112,
        "child_key": 0
    },
    {
        "species": [
            60,
            61,
            62
        ],
        "desc": "Keha selgelt pikenenud. Selja- ja pärakuuim pikad. Kõhuuimed väga väikesed, lühikesed või kitsad (võivad puududa, meie liikidel olemas). Merekalad.",
        "key_id": 2115222118,
        "extra": "",
        "parent_key": 2111222112,
        "child_key": 2115222123
    },
    {
        "species": [
            60,
            61
        ],
        "desc": "Sabauime selgesti eristunud, piklik või ümardunud.",
        "key_id": 2115222123,
        "extra": "",
        "parent_key": 2115222118,
        "child_key": 2115222125
    },
    {
        "species": [
            60
        ],
        "desc": "Lõpuseavad ulatuvad tugevasti ettepoole, mõlemapoolsed kidekiled on kitsalt (ainult ees kurgu all) teineteisega liitunud. Pärakuuime alguses kuni 5 ogakiirt. Sabauim piklik-lantsetjas, ümardunud. Keha pikk, külgedelt kergelt lamenenud. Merekalad. Sugukond: ridakalalased, liik: suttlimusk.",
        "key_id": 2115222125,
        "extra": "",
        "parent_key": 2115222123,
        "child_key": 0
    },
    {
        "species": [
            61
        ],
        "desc": "Lõpuseavad ei ulatu ette, kidekiled on kasvanud omavahel kokku ahikul ristiasetuvaks laiaks kurruks. Pärakuuime alguses 1-2 oga. Sabauim ümardunud. Lühike. Keha pikk, külgedelt tunduvalt lamenenud. Sugukond: võikalalased, liik: võikala.",
        "key_id": 2115222126,
        "extra": "",
        "parent_key": 2115222123,
        "child_key": 0
    },
    {
        "species": [
            62
        ],
        "desc": "Selja- ja pärakuuim liituvad väikese sabauimega ühtseks lindiks. Mõned liigid (ka Eestis) on poegivad. Sugukond: emakalalased, liik: emakala.",
        "key_id": 2115222124,
        "extra": "",
        "parent_key": 2115222118,
        "child_key": 0
    },
    {
        "species": [
            70,
            71
        ],
        "desc": "Ülalõualuu (maxillare) ja eesülalõualuu (praemaxillare) moodustavad liikumatu terviku. Kõhuuime olemasolu korral leiduvad sabal, teise seljauime ja pärakuuime taga, mõned väiksed uimekesed. Kui kõhuuim puudub, siis lõuad on pikaks venitunud. Merekalad",
        "key_id": 211122212,
        "extra": "",
        "parent_key": 21112221,
        "child_key": 2111222121
    },
    {
        "species": [
            70
        ],
        "desc": "Kõhuuimed olemas. Kask üksteisest eemal paiknevat seljauime. Sabavarrel selja- ja pärakuuime taga mõned lisauimekesed. Lõuad moodustavad lühikese võimsa kiilu. Hambad lõugadel ühes reas. Sugukond: makrelllased, liik: atlandi makrell.",
        "key_id": 2111222121,
        "extra": "",
        "parent_key": 211122212,
        "child_key": 0
    },
    {
        "species": [
            71
        ],
        "desc": "Kõhuuimed puuduvad. Noorte kalade ühest pikast seljauimest ja pärakuuimest säilivad suguküpsetel ainult väga kõrge kolmnurkne eesmine ja sabavarrel paiknev tagumine osa. Uimekesed sabavarrel puuduvad. Lõuad on venitunud pikkadeks teravateks mõõkadeks. Hambad lõugadel on kadumas, vaevalt kombatavad. Sugukond: mõõkkalalased, liik: mõõkkala.",
        "key_id": 2111222122,
        "extra": "",
        "parent_key": 211122212,
        "child_key": 0
    },
    {
        "species": [
            63,
            64
        ],
        "desc": "Suuõõnes hambad puuduvad. Kõhuuimi ei ole. Selja- ja pärakuuim pikad. Keha pikk, üsna ruljas. Küljejoon kulgeb selja lähedal. Kidekiled on ahikust vabad, lõpuseavad ulatuvad tugevasti ette. Merekalad. Väiksed, kuni 40cm. Sugukond: tobiaslased.",
        "key_id": 21112222,
        "extra": "",
        "parent_key": 2111222,
        "child_key": 211122221
    },
    {
        "species": [
            63
        ],
        "desc": "Sahkluul hambad puuduvad. Eeslõualõualuud ette väljatõmmatavad. Ülalõualuude eesmised osad ei ole omavahel liitunud. Alalõug rinnauimest lühem. Kõhu keskel ei ole pooride ridu. Liik: väike tobias.",
        "key_id": 211122221,
        "extra": "",
        "parent_key": 21112222,
        "child_key": 0
    },
    {
        "species": [
            64
        ],
        "desc": "Sahkluul 2 tugevat hambataolist jätket. Eesülalõualuud ei ole ette väljatõmmatavad. Ülalõualuude eesmised otsad on laienenud ja omavahel liitunud. Alalõug rinnauimest pikem. Kõhu keskel 3 pikirida peeni poore. Liik: suurtobias.",
        "key_id": 211122222,
        "extra": "",
        "parent_key": 21112222,
        "child_key": 0
    },
    {
        "species": [
            20,
            55,
            56
        ],
        "desc": "Kõhuuimed puuduvad.",
        "key_id": 2112,
        "extra": "",
        "parent_key": 211,
        "child_key": 21121
    },
    {
        "species": [
            20
        ],
        "desc": "Keha pikaks venitatud, usjas. Päraku- ja seljauimed pikad, pehmed, liituvad sabauimega ühtseks tervikuks. Lõpuseava väga väike. Soomused tsükloidsed, väliselt nähtamatud, peituvad sügaval nahas. Selts: angerjalised, liik: angerjas.",
        "key_id": 21121,
        "extra": "Maduja kuju ja liikumisviisiga. Kõhuuimed puuduvad. Selja- ja pärakuuimed pikad, liitunud sabauimega, ogakiiri uimedes ei ole. Areng moondega.",
        "parent_key": 2112,
        "child_key": 0
    },
    {
        "species": [
            55,
            56
        ],
        "desc": "Pika peene kehaga kalad. Ninamik pikk ja torujas. Välisrüü osaliselt või rohkem tihedalt liitunud luuplaadikestest. Kõhuuimed puuduvad. Väikesed merekalad. Selts: merinõelalised.",
        "key_id": 21122,
        "extra": "",
        "parent_key": 2112,
        "child_key": 211221
    },
    {
        "species": [
            55
        ],
        "desc": "Keha pikk, sarnaneb suurele nõelale. Esineb seljauim, sabauim, rinnauimed ja väike pärakuuim. Liik: merinõel.",
        "key_id": 211221,
        "extra": "",
        "parent_key": 21121,
        "child_key": 0
    },
    {
        "species": [
            56
        ],
        "desc": "Keha väga peen, nööritaoline. Uimedest olemas ainult seljauim. Ninamik torujas, suuava tilluke. Liik: madunõel.",
        "key_id": 211222,
        "extra": "",
        "parent_key": 21121,
        "child_key": 0
    },
    {
        "species": [
            4,
            4100,
            4200,
            4300
        ],
        "desc": "Saba selgelt ebasümmeetriline, keha kaetud soomustega, heterotserkset tüüpi. Piki keha kulgeb 5 rida luukilpe. Ninamik lapergune, pika koonusena (nokis e rostrum). Selts: tuuralised.",
        "key_id": 212,
        "extra": "Mitteluustuv painduv seljakeelik, lülikehad puuduvad. Siseskelett kõhrene, peas katteluud. Sabauim erihõlmne, selja- ja pärakuuimes on uimekiiri rohkem kui nende toeselemente. Kõhuuimede kiired omavad toeselemente. Sabauime alusel rombikujulised soomused. Vahekaas ja kidemed puuduvad.",
        "parent_key": 21,
        "child_key": 2121
    },
    {
        "species": [
            4,
            4100
        ],
        "desc": "Pikk ninamik (nokis)",
        "key_id": 2121,
        "extra": "",
        "parent_key": 212,
        "child_key": 21211
    },
    {
        "species": [
            4
        ],
        "desc": "Selja- ja küljekilbiridade vahel puuduvad iseloomulikud luised rombikujulised naastud. Liik: euroopa tuur.",
        "key_id": 21211,
        "extra": "",
        "parent_key": 2121,
        "child_key": 0
    },
    {
        "species": [
            4100
        ],
        "desc": "Selja- ja küljekilbiridade vahel esinevad iseloomulikud luised naastud, mis on erinevalt teistest tuuraliikidest rombikujulised. Liik: atlandi tuur.",
        "key_id": 21212,
        "extra": "",
        "parent_key": 2121,
        "child_key": 0
    },
    {
        "species": [
            4200,
            4300
        ],
        "desc": "Lühike ninamik (nokis)",
        "key_id": 2122,
        "extra": "",
        "parent_key": 21221,
        "child_key": 21221
    },
    {
        "species": [
            4200
        ],
        "desc": "Ninamik suhteliselt lühike ja lai, alumisel küljel paikneb neli küllalt pikka ja paksu poiset. Seljakilpe reas 10-19, küljekilpe reas 32-59. Liik: siberi tuur.",
        "key_id": 21221,
        "extra": "",
        "parent_key": 2122,
        "child_key": 0
    },
    {
        "species": [
            4300
        ],
        "desc": "Ninamik suhteliselt lühike ja ümaratipuline, kitsam kui siberi tuural. Nokise alumisel küljel paikneb neli peenikest poiset, mis ei ulatu suuavani. Seljakilpe reas 7-19, küljekilpe reas 24-44. Liik: vene tuur.",
        "key_id": 21222,
        "extra": "",
        "parent_key": 2122,
        "child_key": 0
    }
]

export default keysData;