/**
 * This file is used to create a context for the authentication of the user.
 * https://react.dev/reference/react/useContext
 * Import this file in any component that needs to access the authentication state.
 * Import { useAuth } from './components/AuthContext';
 * Then use it like this:
 * const { authenticated, setAuthenticated } = useAuth();
 * Then use the authenticated state to show/hide components or redirect to another page.
 *
 * Includes also function handleLogout that can be used among components.
 */

import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(false);

    const handleLogout = async () => {
        try {
            const token = localStorage.getItem('token');

            const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '',
                },
            });

            const data = await response.json();

            if (data.success) {
                localStorage.removeItem('token');
                setAuthenticated(false);
            } else {
                setAuthenticated(true);
            }
        } catch (error) {
            setAuthenticated(false);
        }
    };

    return (
        <AuthContext.Provider value={{ authenticated, setAuthenticated, handleLogout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
