// Create add user component
// Path: src/components/AddUser.js

import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import TopNav from './TopNav';
import {useAuth} from "./AuthContext";
import AdminRoute from "./AdminRoute";

const AddUser = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const {authenticated, setAuthenticated, handleLogout} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Eesti kalad | Lisa kasutaja';
    }, []);

    const handleAddUser = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/adduser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '',
                },
                body: JSON.stringify({username, password, name, email, role}),
            });

            if (response.redirected) {
                // Handle redirects, navigate to response.url
                //window.location.href = response.url;
                setAuthenticated(false);
            }

            const data = await response.json();

            if (data.success) {
                console.log(data.message);
                setMessage(data.message);
                setAuthenticated(true);
            } else {
                setError(true);
                console.log(data.message);
                setMessage(data.message);
                setAuthenticated(false);
            }

        } catch (error) {
            if (error.response) {
                // Server responded with a status other than 200 range
                setError(true);
                setMessage(error.response.data.message);
                console.log(error.response.data.message);
            }
        }
    };

    return (
        <div>
            <TopNav/>
            <AdminRoute/>
            {authenticated ? (
            <div className="row panel">
                <h2>Lisa kasutaja</h2>
                <form id="login-form" onSubmit={(e) => {
                    e.preventDefault();
                    handleAddUser();
                }}>
                    <input
                        required
                        type="text"
                        placeholder="Kasutajanimi"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        required
                        type="password"
                        placeholder="Parool"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        required
                        type="text"
                        placeholder="Nimi"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <input
                        required
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <select required id="role" name="role" value={role} onChange={(e) => setRole(e.target.value)}>
                        <option value="" disabled>Vali roll</option>
                        <option value="admin">Administraator</option>
                        <option value="visitor" disabled>Külastaja</option>
                        <option value="unknown" disabled>Teadmata</option>
                    </select>
                    <button type="submit">Lisa kasutaja</button>
                    <button onClick={() => navigate(-1)}>Tühista</button>
                </form>
                {message && <p>{message}</p>}
                {error && <p>{error}</p>}
                <a onClick={() => navigate(-1)}>&lt; Tagasi</a>
            </div>
            ) : (
                window.location.href = '/login'
            )}

        </div>
    );
}

export default AddUser;