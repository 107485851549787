import React, {useState, useEffect} from 'react';
import TopNav from './TopNav';
import {useNavigate} from 'react-router-dom';
import {useAuth} from './AuthContext';
import AdminRoute from './AdminRoute';

const List = () => {
    const [estList, setEstList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('Leitud liigid');
    const [images, setImages] = useState(null);

    const urlParams = new URLSearchParams(window.location.search);
    const gid = urlParams.get('gid');
    const lid = urlParams.get('lid');
    const top = urlParams.get('top');
    const navigate = useNavigate();

    const seltsidEst = ["Silmulised", "Tuuralised", "Angerjalised", "Heeringalised", "Karpkalalised", "Sägalised", "Lõhelised", "Haugilised",
        "Tindilised", "Tursalised", "Kefaalilised", "Tuulehaugilised", "Ogalikulised", "Merinõelalised", "Lestalised", "Meripuugilised",
        "Ahvenalised"]

    const seltsidLatin = ["Petromyzontiformes", "Acipenseriformes", "Anguilliformes", "Clupeiformes", "Cypriniformes", "Siluriformes",
        "Salmoniformes", "Esociformes", "Osmeriformes", "Gadiformes", "Mugiliformes", "Beloniformes", "Gasterosteiformes",
        "Syngnathiformes", "Pleuronectiformes", "Scorpaeniformes", "Perciformes"]


    const seltsideGrupid = [[1, 2, 3],
        [4, 4100, 4200, 4300],
        [20],
        [5, 6, 7, 8],
        [201, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 412, 413, 42, 43, 44],
        [45, 451, 46],
        [91, 92, 93, 9, 10, 11, 12, 13, 14, 141, 15, 151, 16],
        [19],
        [17, 18],
        [48, 49, 491, 50, 51],
        [401, 402],
        [47],
        [52, 53, 54],
        [55, 56],
        [78, 79, 80, 81, 811],
        [72, 722, 73, 74, 75, 76, 77, 777],
        [57, 571, 58, 59, 591, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71]];

        const fetchData = async () => {
            try {
                const estListResponse = await fetch(`${process.env.REACT_APP_API_URL}/species`);
                const estListData = await estListResponse.json();
                setEstList(estListData);

            let title = '';
            setLoading(false);
            if (gid === '0' && gid < seltsidEst.length) {
                title = `Klass SILMUD <i>Cephalaspidomorphi</i>, selts ${seltsidEst[gid]} <i>${seltsidLatin[gid]}</i>`;
            } else if (gid > '0' && gid < seltsidEst.length) {
                title = `Klass KIIRUIMSED <i>Actinopterygii</i>, selts ${seltsidEst[gid]} <i>${seltsidLatin[gid]}</i>`;
            } else if (gid === '100') {
                title = 'Klass SILMUD <i>Cephalaspidomorphi</i>';
            } else if (gid === '200') {
                title = 'Klass KIIRUIMSED <i>Actinopterygii</i>';
            } else if (top === '1') {
                title = "Leitud liigid";
            }
            setTitle(title);

                const imagesResponse = await fetch(`${process.env.REACT_APP_API_URL}/images`);
                const imagesData = await imagesResponse.json();
                setImages(imagesData);
            } catch (error) {
                console.error('Error:', error);
            }
        };

    useEffect(() => {
        document.title = 'Eesti kalad | Nimekiri';
        setLoading(true);
    }, []);

    const imageMap = {};
    // Check if images is not null before iterating
    if (images && Array.isArray(images)) {
        images.forEach((image) => {
            const imageSrc = `img/t/${image.imageSrc}_t.png`;
            imageMap[image.fid_estfish_id] = imageSrc;
        });
    }

    const renderList = () => {
        if (estList && estList.length > 0) {
            const currentGroup = seltsideGrupid[gid];

            return (
                <>
                    <ul className="list">
                        {estList.map((fish) => {
                            if (fish.species_id < 104) {
                                let matchingImageSrc = null;

                                if (gid === "100") {
                                    for (let i = 0; i <= 3; i++) {
                                        if (fish.fid_estfish_id == i && imageMap[i]) {
                                            matchingImageSrc = imageMap[i];
                                            break;
                                        }
                                    }
                                } else if (gid === "200") {
                                    for (let i = 4; i < 4300; i++) {
                                        if (fish.fid_estfish_id == i && imageMap[i]) {
                                            matchingImageSrc = imageMap[i];
                                            break;
                                        }
                                    }
                                } else if (gid === "0" || gid > "0") {
                                    for (let i = 0; i < currentGroup.length; i++) {
                                        if (fish.fid_estfish_id == currentGroup[i] && imageMap[currentGroup[i]]) {
                                            matchingImageSrc = imageMap[currentGroup[i]];
                                            break;
                                        }
                                    }
                                }

                                if (matchingImageSrc) {
                                    return (
                                        <li key={fish.fid_estfish_id} className="mylist">
                                            <a href={`Description?id=${fish.species_id}`}>
                                                <img
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    width="100px"
                                                    src={matchingImageSrc}
                                                    alt={`${fish.estName}`}
                                                />
                                                <span
                                                    className="listtext">{fish.estName}, <i>{fish.latinName}</i></span>
                                            </a>
                                        </li>
                                    );
                                }
                            }

                            return null;
                        })}
                    </ul>
                </>
            );
        } else {
            return <p>Ei leitud vasteid.</p>;
        }
    };


    const renderKeys = () => {
        const imageMap = {};
        // Check if images is not null before iterating
        if (images && Array.isArray(images)) {
            images.forEach((image) => {
                const imageSrc = `img/t/${image.imageSrc}_t.png`;
                imageMap[image.fid_estfish_id] = imageSrc;
            });
        }

        if (estList && estList.length > 0 && top === '1') {
            return (
                <>
                    <ul className="list">
                        {estList.map((fish) => {
                            const listIdsArray = lid.split(',');
                            return listIdsArray.map((listIds, i) => {

                                let matchingImageSrc = null;

                                // Loop through images array
                                if (images && Array.isArray(images)) {
                                    for (let j = 0; j <= 4300; j++) {
                                        if (fish.species_id < 104 && listIds == fish.fid_estfish_id &&
                                            fish.fid_estfish_id == j && imageMap[j]) {
                                            matchingImageSrc = imageMap[j];
                                            break;
                                        }
                                    }
                                }

                                // Check if matchingImageSrc is not null before rendering
                                return matchingImageSrc && (
                                    <li key={fish.fid_estfish_id} className="mylist">
                                        <a href={`Description?id=${fish.species_id}`}>
                                            <img
                                                onContextMenu={(e) => e.preventDefault()}
                                                width="100px"
                                                src={matchingImageSrc}
                                                alt={`${fish.estName}`}
                                            />
                                            <span className="listtext">{fish.estName}, <i>{fish.latinName}</i></span>
                                        </a>
                                    </li>
                                );
                            });
                        })}
                    </ul>
                </>
            );
        } else {
            return <p>Ei leitud vasteid.</p>;
        }
        ;
    };

    return (
        <>
            <TopNav/>
            <AdminRoute/>
            <div className="row">
                <div className="large-12 columns">
                    <div className="panel">
                        {useEffect(() => {
                                fetchData((data) => {
                                    if (data.length > 0) {
                                        setEstList(data);
                                    } else {
                                    }
                                });
                            }, []
                        )}
                        <div>
                            <h3 dangerouslySetInnerHTML={{__html: title}}></h3>
                            {estList.length === 0 && <p>Laadin liike.</p>}
                            {top === '0' && estList.length > 0 && (
                                <ul className="list">
                                    {renderList()}
                                </ul>
                            )}
                            {top === '1' && estList.length > 0 && (
                                <ul className="list">
                                    {renderKeys()}
                                </ul>
                            )}
                        </div>
                        <a onClick={() => navigate(-1)}>&lt; Tagasi</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default List;