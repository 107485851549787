import React, {useEffect, useState} from 'react';
import TopNav from "./TopNav";
import AdminRoute from './AdminRoute';
import {Link} from "react-router-dom";
import { ArrowRight } from 'react-feather';

const Main = () => {
    useEffect(() => {
        document.title = 'Eesti kalad | Esileht';
    }, []);


    return (
        <>
        <TopNav/>
        <AdminRoute/>
        <div className="row">
            <div className="large-12 columns">
                <div className="panel" style={{fontSize: '100%'}}>
                    <em>
                        <b>Peamised muudatused 2024. aastal: </b>kasutajatel on nüüd oluliselt mugavam rakenduse sees enda 
                        tehtud pilte kalanduse teabekeskusele saata või märkida linnukesega, et KalaAjule 
                        määramiseks üles laetavad pildid saadetakse lisaklikke tegemata ka kalanduse teabekeskusele
                    </em>
                    <br/>
                    <br/>

                    <h3>Eesti kalad</h3>
                    {/*<p>Käesoleva määraja abil on võimalik tutvuda kõigi Eesti vetest püütud kaladega ja käsitletud
                        on ka liike,
                        keda veel Eesti aladelt saadud pole, kuid kes meie lähipiirkonnas on levinud, näiteks
                        ebarasboora ja arktika paalia. Kokku 102 kalaliiki.</p>*/}

                        <p>
                        Käesoleva rakenduse abil on võimalik tutvuda Eesti vetest püütud kaladega ja käsitletud on ka liike, 
                        keda veel Eesti aladelt saadud pole, kuid kes meie lähipiirkonnas on levinud, näiteks ebarasboora 
                        ja arktika paalia. Kokku 103 kalaliiki.
                        </p>

                    <h3>KalaAju</h3>
                    <p> “KalaAju” on tehisintellekti abil väljatöötatud fotolt kalaliigi määramise mudel, mis määrab
                        fotolt kalaliigi ja kus saab pildistada või valida olemasoleva foto.
                    </p>

                    {/*The App in Store is not available at the moment*/}
{/*                    <p align="center">
                        <a href="https://play.google.com/store/apps/details?id=ee.kalateave.mobile.eestikalad">
                            <img src="/icon/GooglePlayUus.png" alt="Get it on Google Play" style={{height: '2em'}}
                                 className="p-2"/>
                        </a>
                    </p>*/}

                    <h3>Eesti kalade klassifikatsioon</h3>
                    <br/>
                    <p><a href="listAll" className="button" style={{alignItems: 'center', display: 'flex', width: 'fit-content'}}>
                        Vaata kõiki liike &nbsp; <ArrowRight/></a></p>
                    <p><u><a href="List?gid=100&top=0">klass Silmud
                        (<i>Cephalaspidomorphi</i>)</a></u></p>
                    <p>&emsp;<a href="List?gid=0&top=0">selts Silmulised
                        (<i>Petromyzontiformes</i>)</a></p>
                    <p><u><a href="List?gid=200&top=0">klass Kiiruimsed (<i>Actinopterygii</i>)</a></u></p>
                    <p>&emsp;<a href="List?gid=1&top=0">selts Tuuralised (<i>Acipenseriformes</i>)</a></p>
                    <p>&emsp;<a href="List?gid=2&top=0">selts Angerjalised (<i>Anguilliformes</i>)</a></p>
                    <p>&emsp;<a href="List?gid=3&top=0">selts Heeringalised (<i>Clupeiformes</i>)</a></p>
                    <p>&emsp;<a href="List?gid=4&top=0">selts Karpkalalised (<i>Cypriniformes</i>)</a></p>
                    <p>&emsp;<a href="List?gid=5&top=0">selts S&auml;galised (<i>Siluriformes</i>)</a></p>
                    <p>&emsp;<a href="List?gid=6&top=0">selts L&otilde;helised (<i>Salmoniformes</i>)</a></p>
                    <p>&emsp;<a href="List?gid=7&top=0">selts Haugilised (<i>Esociformes</i>)</a></p>
                    <p>&emsp;<a href="List?gid=8&top=0">selts Tindilised (<i>Osmeriformes</i>)</a></p>
                    <p>&emsp;<a href="List?gid=9&top=0">selts Tursalised (<i>Gadiformes</i>)</a></p>
                    <p>&emsp;<a href="List?gid=10&top=0">selts Kefaalilised (<i>Mugiliformes</i>)</a></p>
                    <p>&emsp;<a href="List?gid=11&top=0">selts Tuulehaugilised (<i>Beloniformes</i>)</a></p>
                    <p>&emsp;<a href="List?gid=12&top=0">selts Ogalikulised (<i>Gasterosteiformes</i>)</a></p>
                    <p>&emsp;<a href="List?gid=13&top=0">selts Merin&otilde;elalised (<i>Syngnathiformes</i>)</a>
                    </p>
                    <p>&emsp;<a href="List?gid=14&top=0">selts Lestalised (<i>Pleuronectiformes</i>)</a></p>
                    <p>&emsp;<a href="List?gid=15&top=0">selts Meripuugilised (<i>Scorpaeniformes</i>)</a></p>
                    <p>&emsp;<a href="List?gid=16&top=0">selts Ahvenalised (<i>Perciformes</i>)</a></p>
                </div>
            </div>
        </div>

        </>
    );
}

export default Main;
