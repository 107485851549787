import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TopNav from "./TopNav"; // Import Link from react-router-dom for navigation
import AdminRoute from './AdminRoute';

function Dictonary() {
    const [dicts, setDicts] = useState([]);
    const title = "Mõisted"

    useEffect(() => {
        document.title = 'Eesti kalad | Mõisted';
    }, []);

    const fetchData = async () => {
        fetch(`${process.env.REACT_APP_API_URL}/dict`)
            .then((response) => response.json())
            .then((data) => {
                setDicts(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const dictImageProvided = [
        "ahik",
        "alal6uahaak",
        "kidekile",
        "kyljejoon",
        "liitunud_uimed1_ja_kiirtega_uimed",
        "ninamik",
        "ogakiired",
        "pois",
        "profiil_erih6lmne",
        "profiil_k6huuim1",
        "profiil_p2rakuuim1",
        "profiil_rasvauim",
        "profiil_rinnauim1",
        "profiil_sabauim",
        "profiil_seljauim",
        "seljauime_pikkus",
        "suu_seisud_alaseisune",
        "suu_seisud_otseseisune",
        "suu_seisud_poolalaseisune",
        "suu_seisud_ylaeseisune",
        "suur_l",
        "uime_v2lisserv",
        "uimekiired",
        "v2ike_l"
    ];

    return (
        <>
        <TopNav />
        <AdminRoute />
        <div className="row">
            <div className="large-12 columns">
                <div className="panel">
                    <h3>{title}</h3>
                    <br/>
                    {useEffect(() => {
                        fetchData((data) => {
                            if (data.length > 0) {
                                setDicts(data);
                            } else {
                            }
                        });
                    }, [])   }
                    {dicts.length === 0 && <p>Laadin andmeid.</p>}
                    {dicts && dicts.length > 0 ? (
                        <ul className="list">
                        {dicts.map((dict, index) => (
                                <li key={index} className="mylist">
                                    <span className="name" style={{ fontWeight: 'bold' }}>{dict.estName}</span>
                                    <div className="desc" style={{ fontSize: '14px' }}>
                                        {dict.explanation}
                                        {dictImageProvided.includes(dict.img) && <><br /><img src={`img/dict/${dict.img}.jpg`} alt={dict.estName} style={{ height: '175px' }} /></>}
                                        <br />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : null}
                    <br /><br /><br />
                </div>
            </div>
        </div>
        </>
    );
}

export default Dictonary;
