import React, {useState, useEffect, useRef} from 'react';
import TopNav from "./TopNav";
//import * as worker from '../js/kalaaju/kalaaju-web.eaa29eb8.js';
// This was in original worker file commented out as // For backup
//import * as tfb_wasm from '@tensorflow/tfjs-backend-wasm';
//import * as tf from '@tensorflow/tfjs';
import Classifier from "./Classifier";
import AdminRoute from './AdminRoute';

/**
 * https://towardsdatascience.com/how-to-use-tensorflow-js-in-react-js-object-detection-98b3782f08c2
 */

const KalaAjuComponent = () => {
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState({});
    const [failed, setFailed] = useState(false); // If true, then show "Tuvastamine ebaõnnestus"
    const [question, setQuestion] = useState(null); // Holds the question to pseudo species
    const [answers, setAnswers] = useState([]); // Holds the answers to the question
    const [chosen, setChosen] = useState({
        link_fid: '',
        link_species_id: '',
        name: '',
        latin_name: '',
        features: '',
        size: '',
        limitations: ''
    }); // species name from classifier and answered
    const classes = []; // Holds the initial classes including pseudoClasses (species names) from classifier
    const [results, setResults] = useState([]); // Holds the results (classes) from classifier
    const [acceptSendToDatabase, setAcceptSendToDatabase] = useState(false); // Holds the answer to "Luban saata üleslaetud foto KalaAju mudeli treenimiseks"
    const token = localStorage.getItem('token');
    const [showSimpleCaptcha, setShowSimpleCaptcha] = useState(false);
    const [simpleCaptchaAnswer, setSimpleCaptchaAnswer] = useState(''); // Holds the answer to "Viis pluss viis võrdub?"
    const modelRef = useRef(null); // Ref for the model
    const imgRef = useRef(null); // Ref for the image
    const [imageSrc, setImageSrc] = useState(''); // Holds the uploaded image source
    const [lastFileData, setLastFileData] = useState(''); // Holds the last uploaded file data
    const [fileInputValue, setFileInputValue] = useState(''); // Holds the value of the file input
    const [modelPhotoUploadMessage, setModelPhotoUploadMessage] = useState(''); // Holds the message of the model photo upload
    const hideNoMoreInfo = ['meriforell või jõeforell', 'kalaaju muu kala', 'kalaaju ei leidnud kala'];

    useEffect(() => {
        document.title = 'Eesti kalad | KalaAju';
    }, []);

    const fetchData = async (speciesName, isPseudoClass) => {
        try {
            // console.log('Fetching data for speciesName: ' + speciesName + ' isPseudoClass: ' + isPseudoClass);
            // Replace "Meriforell/Jõeforell" with "Meriforell või Jõeforell". Replacements for pseudoSpecies
            speciesName = speciesName.replace('Meriforell/Jõeforell', 'Meriforell või Jõeforell');
            speciesName = speciesName.replace('other', 'kalaaju ei leidnud kala');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/description/${speciesName}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    isPseudoClass: isPseudoClass
                })
            });

            const data = await response.json();

            if (data.success && data.message.length > 0) {
                const message = data.message[0];
                setChosen({
                    link_fid: message.fid_estfish_id,
                    link_species_id: message.species_id,
                    name: message.estName,
                    latin_name: message.latinName,
                    features: message.characteristics,
                    size: message.dimensions,
                    limitations: message.protection_and_restriction
                });
            } else {
                setModelPhotoUploadMessage(data.message);
                //console.log(data.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    function handleFileUploadToDatabase() {
        // Add simple captcha that asks user "Viis pluss viis võrdub?" and user has to answer "kümme" or "10"
        setShowSimpleCaptcha(true);
        if (simpleCaptchaAnswer === 'kümme' || simpleCaptchaAnswer === '10') {
            setShowSimpleCaptcha(false);

            const files = document.getElementById("file-upload-to-database").files;
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                formData.append('photos[]', file);
            }

            fetch(`${process.env.REACT_APP_API_URL}/uploadPhoto`, {
                method: 'POST',
                headers: {
                    'Authorization': token ? `Bearer ${token}` : '',
                },
                body: formData
            })
                .then(response => {
                    if (!response.ok) {
                        // If response status is not OK, throw an error
                        return response.text().then(text => {
                            throw new Error(text || 'Serveri viga!');
                        });
                    }
                    return response.json();
                })
                .then(data => {
                    //console.log(data);
                    setMessage(data.message);
                })
                .catch(error => {
                    console.error('Error:', error);
                    setError(error);
                    setMessage({ error: true, message: error.toString() });
                });
        } else {
            // console.log('CAPTCHA not verified');
            // Display a message to the user to solve the CAPTCHA first
            setMessage(['Solve the CAPTCHA first']);
        }
    }

    const size_question = 'Kui pikk on püütud kala?';
    const salt_question = 'Kust kala püütud on?';
    const sea_lake_question = 'Kas kala on püütud Peipsi vesikonna veekogust?';
    const salt_answers = [{
        label: 'Merest',
        id: 0
    }, {
        label: 'Mageveest',
        id: 1
    }];
    const sea_lake_answers = [{
        label: 'Jah',
        id: 1
    }, {
        label: 'Ei',
        id: 0
    }];
    const sea_lake_wide_answers = [{
        label: 'Merest',
        id: 0
    }, {
        label: 'Mageveest',
        id: 1
    }];

    const questions = {
        'Forell': {
            question: salt_question,
            answers: sea_lake_wide_answers
        },
        'Siig': {
            question: sea_lake_question,
            answers: sea_lake_answers
        },
        'Tint': {
            question: sea_lake_question,
            answers: sea_lake_answers
        },
        'Tobias': {
            question: size_question,
            answers: [{
                label: '<15cm',
                id: 0
            }, {
                label: '20cm+',
                id: 1
            }]
        }
    };

    // Assign real species names to pseudo species classes
    const realSpecies = {
        'Siig': [{
            name: 'Merisiig',
            isPseudoClass: true,
        },
            {
                name: 'Peipsi siig',
                isPseudoClass: true,
            }],
        'Tobias': [{
            name: 'Väike tobias',
            isPseudoClass: true,
        },
            {
                name: 'Suurtobias',
                isPseudoClass: true,
            }],
        'Tint': [{
            name: 'Meritint',
            isPseudoClass: true,
        },
            {
                name: 'Peipsi tint',
                isPseudoClass: true,
            }],
        'Forell': [{
            name: 'Meriforell',
            isPseudoClass: true,
        },
            {
                name: 'Meriforell/Jõeforell',
                isPseudoClass: true,
            },
            {
                name: 'Jõeforell',
                isPseudoClass: true,
            }]
    };

    // Handle answering a question
    const answer = async (selectedAnswer) => {
        const newSpeciesName = realSpecies[results[0].name][selectedAnswer.id];
        const newisPseudoClass = true;
        /*        console.log('Answered: ' + selectedAnswer.label + ' (' + selectedAnswer.id + ')');
                console.log('SpeciesName: ' + newSpeciesName['name']);
                console.log('isPseudoClass: ' + newisPseudoClass);*/

        // Call fetchData with the new values
        await fetchData(newSpeciesName['name'], newisPseudoClass);

        setQuestion(null);

        // Handle Kõik variandid
        let mostProbableClass = results[0];
        let updatedResults = [];

        realSpecies[mostProbableClass.name].forEach((realSpeciesItem) => {
            updatedResults.push({
                name: realSpeciesItem.name, // Access the name property directly
                isPseudoClass: realSpeciesItem.isPseudoClass // Access the isPseudoClass property directly
            });
        });

        // If classes.length > 1 then there are more than one class. Add the other classes to updatedResults with isPseudoClass = false
        if (classes.length > 1) {
            classes.forEach((classItem) => {
                if (classItem.name !== mostProbableClass.name) {
                    updatedResults.push({
                        name: classItem.name, // Access the name property directly
                        isPseudoClass: false // Access the isPseudoClass property directly
                    });
                }
            });
        }

        setResults(updatedResults);
        // console.log('Results: ' + JSON.stringify(updatedResults));


    };

    const reset = () => {
        setQuestion(null);
        setResults([]);
        setFailed(false);
        setChosen(null);
        setError(null);
        setModelPhotoUploadMessage(null);
        classes.length = 0;
    };

    const handleImageOnLoad = async (evt) => {

        reset();
        let file = evt.target.files[0];
        if (!file) {
            return;
        }
        if (!file.type.match('image.*')) {
            setError('Valitud fail ei sisaldanud pilti!');
            return;
        }

        setProcessing(true);

        const reader = new FileReader();

        reader.onload = async (e) => {
            // Fill the image & call predict through onLoad event calling handlePhotoClassifier()
            // Ensure the result is treated as a string
            if (typeof e.target.result === 'string') {
                setImageSrc(e.target.result);
            }

            // Handle case when same file is uploaded again. Cannot rely on asynchronous useState hook to update the state immediately
            const fileData = reader.result;
            if (lastFileData && lastFileData === fileData) {
                // If the image is the same as the last image then process it directly
                handlePhotoClassifier();
            }
            // Store last file data for comparison with the new uploaded file
            setLastFileData(fileData);
        }
        reader.readAsDataURL(file);

        // Save the photo to database if acceptSendToDatabase is true
        if (acceptSendToDatabase) {
            const formData = new FormData();
            formData.append('photos[]', file);
            fetch(`${process.env.REACT_APP_API_URL}/uploadPhoto`, {
                method: 'POST',
                headers: {
                    'Authorization': token ? `Bearer ${token}` : '',
                },
                body: formData
            })
                .then(response => {
                    if (!response.ok) {
                        // If response status is not OK, throw an error
                        return response.text().then(text => {
                            throw new Error(text || 'Serveri viga!');
                        });
                    }
                    return response.json();
                })
                .then(data => {
                    //  console.log(data);
                    setMessage(data.message);
                })
                .catch(error => {
                    console.error('Error:', error);
                    setError(error);
                    setMessage({ error: true, message: error.toString() });
                });
        }

        // Reset the file input value to allow uploading the same file again
        setFileInputValue('');

        // Insert event into PhotoIdentification table
        await fetch(`${process.env.REACT_APP_API_URL}/photoIdentification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    const handlePhotoClassifier = async () => {
        try {
            // Use model as Singleton - create once and reuse if needed
            if (!modelRef.current) {
                modelRef.current = new Classifier();
            }

            const model = modelRef.current

            const size = await model.getExpectedSize();
            const imgData = rotateResize(imgRef.current, size[0], size[1]);
            const classes = await model.predict(imgData)

            // Replace the key "className" in classes with the "name" to match the following code
            classes.forEach((classItem) => {
                    classItem.name = classItem.className;
                    delete classItem.className;
                }
            );
            // console.log('Classes: ' , classes);

            // If classes contain Siig, Tobias, Tint or Forell as name then append to each class the questions and answers of that class
            const speciesWithQuestions = Object.keys(questions);
            // Append questions and answers to classes that match the species names
            classes.forEach(classItem => {
                if (speciesWithQuestions.includes(classItem.name)) {
                    setQuestion(questions[classItem.name].question);
                    setAnswers(questions[classItem.name].answers);
                    // Keep in results only the class that has the question
                    setResults([classItem]);
                }
            });
            // Handle Kõik variandid
            setResults(classes);

            showResults(classes);

        } catch (error) {
            setError(error);
        }
    };

    // Rotate and resize image function from original model
    function rotateResize(imgElement, to_width, to_height) {
        var w = imgElement.width,
            h = imgElement.height;
        var rotate = false;

        if (h > w) {
            rotate = true;
            var b = h;
            h = w;
            w = b; // switch w and h
        } // Scale down, but keep aspect ratio


        if (w > to_width) {
            h *= to_width / w;
            w = to_width;
        }

        if (h > to_height) {
            w *= to_height / h;
            h = to_height;
        }

        var canvas = document.getElementById("preprocessedImage"); // Draw on existing (for debug)

        if (!canvas) canvas = document.createElement('canvas'); // Or create a temporary one

        canvas.width = to_width;
        canvas.height = to_height;
        var ctx = canvas.getContext("2d"); // Paint it, black!

        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, canvas.width, canvas.height); // Center

        ctx.translate(to_width / 2, to_height / 2); // Draw image, rotated if needed

        if (rotate) {
            ctx.rotate(-Math.PI / 2);
            ctx.drawImage(imgElement, -h / 2, -w / 2, h, w);
            ctx.rotate(Math.PI / 2);
        } else ctx.drawImage(imgElement, -w / 2, -h / 2, w, h); // Reset transformation


        ctx.translate(-to_width / 2, -to_height / 2);
        return ctx.getImageData(0, 0, to_width, to_height);
    }

    const showResults = (classes, e) => {
        if (classes.length === 0) {
            setFailed(true);
        } else {
            setResults(classes);
            const mostProbableClass = classes[0];
            if (mostProbableClass.name) {
                // Check if the class name is one of the pseudo classes that need name conversion, or is "other"
                const isPseudoClass = Object.keys(realSpecies).includes(mostProbableClass.name) || mostProbableClass.name === 'other';

                if (!isPseudoClass) {
                    // If not a pseudo class, call fetchData directly
                    fetchData(mostProbableClass.name);
                } else {
                    // If it is a pseudo class, ensure the question exists for this class
                    if (questions[mostProbableClass.name]) {
                        setQuestion(questions[mostProbableClass.name].question);
                        setAnswers(questions[mostProbableClass.name].answers);
                        // Keep in results only the class that has the question
                        setResults([mostProbableClass]);
                    } else {
                        // Handle the scenario where the question does not exist for the class, then call fetchData directly (for example for "other")
                        fetchData(mostProbableClass.name, isPseudoClass);
                        console.error(`No question found for class: ${mostProbableClass.name}`);
                        // Implement any additional handling needed when the question is missing
                    }
                }
            }
        }

        setProcessing(false);
    };

    return (
        <>
            <TopNav/>
            <AdminRoute/>
            <div>
                <div className="container bg-white min-vh-100">
                    <div className="row panel">

                        {/*Comment in for Debugging*/}
                        {/*{results && <p>Results: {JSON.stringify(results)}</p>}*/}

                        {!processing && error && (
                            <div className="row pt-4">
                                <div className="large-12 column">
                                    <h2>Tekkis viga</h2>
                                </div>
                            </div>
                        )}

                        {!processing && failed && (
                            <div className="row pt-4">
                                <div className="col-12 p-4 text-center">
                                    <h2>Tuvastamine ebaõnnestus</h2>
                                    <p><b>Parimate tulemuste jaoks tehke pilt nii, et kaadris on kala külgvaates,
                                        võimalikult suurelt ja võimalikult ühtlasel taustal. Samuti võib aidata kala
                                        heinast, lumest ja liivast puhtaks pühkimine.</b></p>
                                </div>
                            </div>
                        )}

                        {!processing && question && (
                            <div className="row">
                                <div className="col-12 py-4 text-center">
                                    <h3 className="py-3">{question}</h3>
                                    <button className="btn btn-lg custom-button my-4 px-4"
                                            onClick={() => answer(answers[0])}>
                                        {answers[0].label}
                                    </button>
                                    <span className="h4 ml-2 mr-2">või</span>
                                    <button className="btn btn-lg custom-button my-4 px-4"
                                            onClick={() => answer(answers[1])}>
                                        {answers[1].label}
                                    </button>
                                </div>
                            </div>
                        )}

                        {!processing && chosen && (
                            <div className="row py-3">
                                <div className="col-12 py-4">
                                    {chosen.name && (
                                        <h3 className="text-uppercase">{chosen.name}, <i>{chosen.latin_name}</i></h3>)}
                                    {modelPhotoUploadMessage && (<h3 align="center">{modelPhotoUploadMessage}</h3>)}
                                    <p className="pt-2" style={{display: chosen.features ? 'block' : 'none'}}>
                                        <b>Tunnused:</b> {chosen.features}
                                    </p>
                                    <p style={{display: chosen.size ? 'block' : 'none'}}>
                                        <b>Mõõtmed:</b> {chosen.size}
                                    </p>
                                    <p style={{display: chosen.limitations ? 'block' : 'none'}}>
                                        <b>Piirangud:</b> {chosen.limitations}
                                    </p>
                                    <div className="my-3"
                                        /*If chosen.name is in hideNoMoreInfo then hide the link*/
                                         style={{display: (chosen.link_species_id && !hideNoMoreInfo.includes(chosen.name)) ? 'block' : 'none'}}>
                                        <a href={`/Description?id=${chosen.link_species_id}`} rel="noopener noreferrer">
                                            Rohkem infot
                                        </a>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        )}

                        {/* Hidden image for extracting attributes in rotateResize() */}
                        <img
                            ref={imgRef}
                            src={imageSrc}
                            onLoad={handlePhotoClassifier}
                            //onError={() => console.error("Error loading image")}
                            style={{display: 'none'}} // Hide the image
                            alt="Hidden Fish"
                        />

                        {!processing && (results.length > 0 || failed) && (
                            <div className="row">
                                <div className="col-12 h-25 bg-white text-center">
                                    <img id="fish-image" src={imageSrc}
                                         className="img-fluid mx-auto d-block pb-3" alt="Fish"/>
                                    <br/><br/>
                                </div>
                            </div>
                        )}

                        {!processing && chosen && results.length > 1 && (
                            <div className="row">
                                <div className="col-12 text-center">
                                    <b>Kõik variandid: </b>
                                    {results.map((res) => (
                                        <span key={res.name}>
                                {res === chosen ? (
                                    <span>{res.name}</span>
                                ) : (
                                    <a href={res.link_species_id}
                                       onClick={() => fetchData(res.name, res.isPseudoClass)}>
                                        {res.name.toLowerCase()}
                                    </a>
                                )}&nbsp;</span>
                                    ))}
                                </div>
                                <br></br>
                            </div>
                        )}

                        {processing && (
                            <div className="row">
                                <div className="col-12 p-4 text-center">
                                    <div className="spinner-border text-primary custom-spinner" role="status">
                                        <h3 className="sr-only">Töötlen, palun oota</h3>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="row" style={{display: processing || question ? 'none' : 'block'}}>
                            {!(results.length > 0 || failed || error) && (
                                <div className="col-12 py-4 text-center">
                                    <img src="img/kalaaju_banner.jpg" style={{width: '100%'}} alt="Banner"/>
                                    <br/><br/>
                                </div>
                            )}


                            <div className="col-12 py-4 text-center">
                                <h3 className="py-3">{results.length > 0 ? 'Järgmine kala:' : 'Kala määramiseks tee temast pilt:'}</h3>
                                <div className="col-12 py-4 text-center">
                                    <input
                                        type="checkbox"
                                        id="accept-send-to-database"
                                        checked={acceptSendToDatabase}
                                        onChange={() => setAcceptSendToDatabase(!acceptSendToDatabase)}
                                    />
                                    <label htmlFor="accept-send-to-database" className="text-center">
                                        Luban saata üleslaetud foto KalaAju mudeli treenimiseks
                                    </label>
                                </div>
                                <label htmlFor="file-upload" className="button">
                                    Pildista<br/>
                                    <span style={{fontSize: '0.9rem'}}>või lae üles olemasolev pilt</span>
                                </label>
                                <input
                                    style={{
                                        opacity: 0,
                                        cursor: 'pointer'
                                    }}
                                    id="file-upload"
                                    type="file"
                                    accept="image/*"
                                    value={fileInputValue}
                                    onChange={handleImageOnLoad}
                                />
                            </div>
                        </div>
                        <div className="col-12 py-4 text-center">
                            <label htmlFor="file-upload-to-database" className="button"
                                   style={{display: acceptSendToDatabase ? 'none' : 'initial'}}>
                                Saada fotosid KalaAju mudeli treenimiseks
                            </label>
                            <br/>
                            <input
                                style={{
                                    opacity: 0,
                                    cursor: 'pointer'
                                }}
                                id="file-upload-to-database"
                                type="file"
                                accept="image/*"
                                multiple
                                onChange={handleFileUploadToDatabase}
                            />
                            {/*<MathCaptcha onVerification={handleVerification} />*/}
                            {showSimpleCaptcha && (
                                <div className="panel">
                                    <p>Viis pluss viis võrdub?</p>
                                    <input
                                        type="text"
                                        value={simpleCaptchaAnswer}
                                        onChange={(e) => {
                                            setSimpleCaptchaAnswer(e.target.value);
                                        }}
                                    />
                                    <input
                                        className="button"
                                        type="button"
                                        value="OK"
                                        onClick={() => {
                                            handleFileUploadToDatabase();
                                        }}
                                    />
                                </div>
                            )}
                            {/* message is an object and to check if is not empty and there is no error*/}
                            {Object.keys(message).length > 0 && !message.error && !showSimpleCaptcha && (
                                <>
                                    <p>Aitäh! {message.successCount} {message.successCount > 1 ? 'fotot' : 'foto'} üles
                                        laetud:</p>
                                    {message.fileNames && (
                                        <ul>
                                            {message.fileNames.map((fileName, index) => (
                                                <li key={index} style={{listStyle: 'none'}}>{fileName}</li>
                                            ))}
                                        </ul>
                                    )}
                                </>
                            )}

                            {/*Display error message from message object*/}
                            {message && message.error && (
                                <p className="error-message">{message.message}. {message.error}</p>
                            )}

                            <br/><br/>
                        </div>
                        <div className="col-12 p-4 text-center" style={{fontSize: '0.9em'}}>
                            Rakenduses kuvatud andmestik on informatiivne ja ei vabasta ebaõigete määrangute
                            puhul seaduslikust vastutusest.
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default KalaAjuComponent;
