import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import Pp from "./Pp";
import TopNav from "./TopNav";
import AdminRoute from "./AdminRoute";

function Info() {
    useEffect(() => {
        document.title = 'Eesti kalad | Info';
    }, []);

    return (
        <>
            <TopNav/>
            <AdminRoute/>
            <div className="row">
                <div className="large-12 columns">
                    <div className="panel">
                        <h3>Rakendus "Eesti kalad ja KalaAju"</h3>
                        {/*<p><b>K&auml;esoleva m&auml;&auml;raja</b> abil on v&otilde;imalik tutvuda k&otilde;igi Eesti 
                            vetest 
                            p&uuml;&uuml;tud kaladega ja k&auml;sitletud on ka liike, 
                            keda veel Eesti aladelt saadud pole, kuid kes meie l&auml;hipiirkonnas on levinud, 
                            n&auml;iteks 
                            ebarasboora ja arktika paalia. Kokku 103 kalaliiki. 
                            Rakendus sisaldab dihhotoomset m&auml;&auml;rajat.</p>*/}

                        <p>
                        <b>Käesoleva rakenduse</b> abil on võimalik tutvuda Eesti vetest püütud kaladega ja käsitletud on ka liike, 
                        keda veel Eesti aladelt saadud pole, kuid kes meie lähipiirkonnas on levinud, näiteks ebarasboora 
                        ja arktika paalia. Kokku 103 kalaliiki.
                        </p>

                        <p>P&uuml;&uuml;gipiirangud ja alammõõdud seisuga: <b>aprill 2024</b>.</p>

                        <p>
                        <b>&ldquo;KalaAju&rdquo;</b> on tehisintellekti abil v&auml;ljat&ouml;&ouml;tatud fotolt kalaliigi m&auml;&auml;ramise mudel, 
                        mis m&auml;&auml;rab fotolt kalaliigi ja kus saab pildistada v&otilde;i valida olemasoleva foto.
                        <br/>Rakenduse kaudu on v&otilde;imalik saata Kalanduse teabekeskusele fotosid KalaAju andmebaasi t&auml;iendamiseks.
                        </p>

                        <br/>
                        <p><b>V&auml;ljaandja:</b> Kalanduse teabekeskus ja Walk&amp;Learn, 2014, 2024</p>
                        <p><b>Autorid:</b> Aare Verliin, Marko Peterson, Silver Sirp, Toomas Armulik, Einar
                            K&auml;rgenberg,
                            Sergei P&otilde;lme</p>

                        <p><b>M&auml;&auml;raja</b> aluseks on v&otilde;etud: Mikelsaar, N., Eesti NSV kalad, 1984.
                        </p>
                        <p><b>Liikide ja r&uuml;hmade</b> organiseerimisel on aluseks v&otilde;etud: Ojaveer. E.,
                            Pihu, E.,
                            Saat, T., Fishes of Estonia, 2003 ja portaalid: http://fishbase.org portaal ja
                            T&Uuml; loodusmuuseumi infosüsteem.</p>
                        <p><b>V&auml;rviliste jooniste autor:</b> Michael J.Loates</p>
                        <p><b>Kasutatud pildid ja tekstimaterjal:</b></p>
                        <p>Берг Л. С. 1948. Рыбы пресных вод СССР и сопредельных стран 1. Издательство АН СССР,
                            Москва-Ленинград. 466 с.</p>

                        <p>Промысловые рыбы СССР. 1949. (Pед. Л. С. Берг, А. С. Богданов, Н. И. Кожин & T. C. Pacc),
                            Пище-промиздат. 787 с.</p>

                        <p>Benecke, B. & Dallmer, E. 1886. Handbuch der Fischzucht und Fischerei. Verlag von Paul
                            Parey,
                            Berlin. 701 pp.</p>

                        <p>Световидов А. Н. 1952. Сельдевые. Фауна СССР. Рыбы. Издательство АН СССР,
                            Москва-Ленинград. 331
                            +53 с.</p>

                        <p>Valle, K. J. 1934. Suomen kalat. Otava, Helsinki. 228 pp.</p>

                        <p>Bapпaxoвcкий H. A. 1898. Oпpeдeлитeль пpecнoвoдныx pыб eвpoпeйскoй Poccuu. Издaниe A. C.
                            Cувopинa, Caнкт-Пeтepбуpг. 240 c.</p>

                        <p>Hикoльcкий Г. B. 1954. Чacтнaя иxтиoлoгия. Coвeтcкaя Нaукa, Mocквa. 458 c.</p>

                        <p>Halme, E. 1954. Pohjolan kalat värikuvina. Werner Söderström osakeyhtiö, Porvoo,
                            Helsinki. 136
                            pp.</p>

                        <p>Жукoв П. И. 1988. Cпpaвoчник пo экoлoгии пpecнoвoдныx pыб. Нayкa и тexникa, Mинcк. 310
                            c.</p>

                        <p>http://urbanfishfarmer.com/</p>

                        <p>http://fl.biology.usgs.gov/Carp_ID/html/rhodeus_sericeus.html</p>

                        <p>http://www.latvijasdaba.lv</p>

                        <p>http://ec.europa.eu/fisheries/marine_species/farmed_fish_and_shellfish/seabass/index_en.htm</p>

                        <p>http://upload.wikimedia.org/wikipedia/commons/7/75/Clarias_gariepinus.jpg</p>

                        <p>http://www.kalapeedia.ee</p>

                        <br/>
                        <p>Vigadest teavitamisel saatke palun info koos telefoni v&otilde;i tahvelarvuti
                            nime/mudeliga
                            e-posti aadressil: kalateave(att)ut.ee</p>
                        <p>Mobiilirakenduste privaatsuspoliitika (<i>privacy policy</i>) <Link to="/Pp">leiate
                            siit</Link>.</p>
                        <p>K&auml;esoleva rakenduse valmimist on rahastatud: 
                            <br/>"Euroopa Kalandusfondi 2007-2013 rakenduskava" meetme 3.1 "&Uuml;histegevused" tegevuse "Muud &uuml;histegevused" vahenditest.
                            <br/>"Euroopa Merendus- ja Kalandusfondi 2014-2020 rakenduskava" vahenditest.
                            <br/>"Euroopa Merendus-, Kalandus-, ja Vesiviljelusfondi rakenduskava 2021–2027" vahenditest.
                        </p>
                        <br/><br/><br/>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Info;
