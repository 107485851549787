import React, {useState, useRef, useEffect} from 'react';
import TopNav from './TopNav';
import {useAuth} from './AuthContext';
import { useNavigate } from 'react-router-dom';
import AdminRoute from './AdminRoute';

const ItemPage = ({id, detect, image_2_not_provided}) => {
    const [fishes, setFishes] = useState([]);
    const [species, setSpecies] = useState(null);
    const [images, setImages] = useState(null);
    const {authenticated, setAuthenticated, handleLogout} = useAuth();
    const [message, setMessage] = useState([]);
    const [error, setError] = useState(false);

    const [editMode, setEditMode] = useState(false);
    const [fieldValues, setFieldValues] = useState({});

    const [updatedDescription, setUpdatedDescription] = useState();
    const [originalDescription, setOriginalDescription] = useState('');
    const [editedField, setEditedField] = useState(null); // Track the currently edited field
    const inputRef = useRef(null);
    const navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const fishId = urlParams.get('id');
    const labels = ['riik', 'hõimkond', 'alamhõimkond', 'klass', 'selts', 'sugukond', 'perekond', 'liik'];

    image_2_not_provided = [4, 4200, 5, 41, 412, 451, 93, 141, 15, 151, 18, 54, 74, 75, 571, 591, 60, 61];
    const fetchData = async () => {
        try {
            const responseDescription = await fetch(`${process.env.REACT_APP_API_URL}/description`);
            const dataFish = await responseDescription.json();
            setFishes(dataFish[fishId - 1]);

            const responseSpecies = await fetch(`${process.env.REACT_APP_API_URL}/species`);
            const dataSpecies = await responseSpecies.json();
            setSpecies(dataSpecies[fishId - 1]);

            const responseImages = await fetch(`${process.env.REACT_APP_API_URL}/images`);
            const dataImages = await responseImages.json();
            setImages(dataImages[fishId - 1]);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        document.title = 'Eesti kalad | Kirjeldus';
    });

    useEffect(() => {
        fetchData((data) => {
        }, []);
    }, []);

    useEffect(() => {
        // Focus on the input element when entering edit mode
        if (editMode && inputRef.current) {
            inputRef.current.focus();
            // Move cursor to the end of the input
            inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
        }
    }, [editMode]);

    const isImage2Enabled = (fishId) => {
        for (let i = 0; i <= 9999; i++) {
            if (species.fid_estfish_id == image_2_not_provided[i]) {
                return null;
            }
        }
        const image2Src = `img/2/${images.imageSrc}_2.jpeg`;
        return image2Src;
    };

    const renderImages = () => {
        if (!images) {
            return null;
        }

        const image1Src = `img/1/${images.imageSrc}.jpeg`;
        const image2Src = `img/2/${images.imageSrc}_2.jpeg`;

        return (
            <>
                <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={image1Src}
                    border="0"
                    style={{maxWidth: '50%'}}
                    alt={`Image 1 for ${species.estName}`}
                />
                {isImage2Enabled(fishId) && (
                    <>
                        <img
                            onContextMenu={(e) => e.preventDefault()}
                            src={image2Src}
                            border="0"
                            style={{maxWidth: '50%'}}
                            alt={`Image 2 for ${species.estName}`}
                        />
                    </>
                )}
            </>
        );
    };

    const handleEditClick = (field, value) => {
        setEditMode(true);
        setEditedField(field);
        setFieldValues({
            ...fieldValues,
            [field]: value,
        });
        // Save the original description when entering edit mode
        setOriginalDescription(value);
    };

    const handleKeyDown = (field, e) => {
        if (e.key === 'Enter') {
            handleSaveClick(field);
        }
    };

    const handleSaveClick = (field) => {
        updateDescription(field)();
        // Reset edit mode and clear the specific field value
        setEditMode(false);
        setEditedField(null);
        setFieldValues({
            ...fieldValues,
            [field]: undefined,
        });
    };

    const handleCancelClick = () => {
        setEditMode(false);
        // Restore the original description when canceling the edit for the specific field
        if (editedField !== null) {
            setFieldValues({
                ...fieldValues,
                [editedField]: updatedDescription,
            });
            setEditedField(null);
        }
    };

    const updateDescription = (updateType) => async () => {
        const token = localStorage.getItem('token');
        const id = fishId;

        try {
            const requestBody = {
                [updateType]: fieldValues[updateType],
            };

            const response = await fetch(`${process.env.REACT_APP_API_URL}/description/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '',
                },
                body: JSON.stringify(requestBody),
            });

            if (response.redirected) {
                setAuthenticated(false);
            }

            const responseData = await response.json();
            setFishes(responseData);
            setEditMode(false);
        } catch (error) {
            console.error('Error updating description:', error);
        }
    };


    return (
        <>
            <TopNav/>
            <AdminRoute/>

            <div className="row">
                <div className="large-12 columns">
                    <div className="panel" >
                        <div>
                            {fishes.length === 0 && <p>Laadin andmeid.</p>}
                            {fishes ? (
                                <ul className="list">
                                    {species && (
                                        <h3>{species.estName.charAt(0).toUpperCase() + species.estName.slice(1)}, <i>{species.latinName}</i>
                                        </h3>
                                    )}
                                    {renderImages()}
                                    <li key={fishes.species_id} className="mylist" style={{borderBottom: 'none'}}>
                                        <br/>

                                        {fishes.characteristics && (
                                            <div>
                                                <p><b>Iseloomulikud tunnused:</b> {' '} {fishes.characteristics}</p>
                                                {authenticated && editMode && fieldValues.characteristics !== undefined ? (
                                                    <>
                                                        <textarea
                                                            rows="4" cols="50"
                                                            ref={inputRef}
                                                            type="text"
                                                            defaultValue={fieldValues.characteristics}
                                                            onChange={(e) => setFieldValues({ ...fieldValues, characteristics: e.target.value })}
                                                            onKeyDown={(e) => handleKeyDown('characteristics', e)}
                                                            style={{ borderBottom: '1px dashed #000', cursor: 'text' }}
                                                        />
                                                        <button onClick={() => handleSaveClick('characteristics')}>Salvesta</button>
                                                        <button onClick={handleCancelClick}>Tühista</button>
                                                    </>
                                                ) : (
                                                    authenticated &&
                                                    <button onClick={() => handleEditClick('characteristics', fishes.characteristics)}>Muuda</button>
                                                )}
                                            </div>
                                        )}

                                        {fishes.dimensions && (
                                            <div>
                                                <p><b>Mõõtmed:</b> {' '} {fishes.dimensions}</p>
                                                {authenticated && editMode && fieldValues.dimensions !== undefined ? (
                                                    <>
                                                        <textarea
                                                            rows="4" cols="50"
                                                            ref={inputRef}
                                                            type="text"
                                                            defaultValue={fieldValues.dimensions}
                                                            onChange={(e) => setFieldValues({ ...fieldValues, dimensions: e.target.value })}
                                                            onKeyDown={(e) => handleKeyDown('dimensions', e)}
                                                            style={{ borderBottom: '1px dashed #000', cursor: 'text' }}
                                                        />
                                                        <button onClick={() => handleSaveClick('dimensions')}>Salvesta</button>
                                                        <button onClick={handleCancelClick}>Tühista</button>
                                                    </>
                                                ) : (
                                                    authenticated &&
                                                    <button onClick={() => handleEditClick('dimensions', fishes.dimensions)}>Muuda</button>
                                                )}
                                            </div>
                                        )}

                                        {fishes.growth && (
                                            <div>
                                                <p><b>Kasv:</b> {' '} {fishes.growth}</p>
                                                {authenticated && editMode && fieldValues.growth !== undefined ? (
                                                    <>
                                                        <textarea
                                                            rows="4" cols="50"
                                                            ref={inputRef}
                                                            type="text"
                                                            defaultValue={fieldValues.growth}
                                                            onChange={(e) => setFieldValues({ ...fieldValues, growth: e.target.value })}
                                                            onKeyDown={(e) => handleKeyDown('growth', e)}
                                                            style={{borderBottom: '1px dashed #000', cursor: 'text'}}
                                                        />
                                                        <button onClick={() => handleSaveClick('growth')}>Salvesta
                                                        </button>
                                                        <button onClick={handleCancelClick}>Tühista</button>
                                                    </>
                                                ) : (
                                                    authenticated &&
                                                    <button onClick={() => handleEditClick('growth', fishes.growth)}>Muuda</button>
                                                )}
                                            </div>
                                        )}

                                        {fishes.similar_species && (
                                            <div>
                                            <p><b>Sarnased liigid:</b>{' '} {fishes.similar_species}</p>
                                                {authenticated && editMode && fieldValues.similar_species !== undefined ? (
                                                    <>
                                                        <textarea
                                                            rows="4" cols="50"
                                                            ref={inputRef}
                                                            type="text"
                                                            defaultValue={fieldValues.similar_species}
                                                            onChange={(e) => setFieldValues({ ...fieldValues, similar_species: e.target.value })}
                                                            onKeyDown={(e) => handleKeyDown('similar_species', e)}
                                                            style={{ borderBottom: '1px dashed #000', cursor: 'text' }}
                                                        />
                                                        <button onClick={() => handleSaveClick('similar_species')}>Salvesta
                                                        </button>
                                                        <button onClick={handleCancelClick}>Tühista</button>
                                                    </>
                                                ) : (
                                                    authenticated &&
                                                    <button onClick={() => handleEditClick('similar_species', fishes.similar_species)}>Muuda</button>
                                                )}
                                            </div>
                                        )}

                                        {fishes.habitat && (
                                            <div>
                                            <p><b>Elukeskkond:</b> {' '} {fishes.habitat}</p>
                                                {authenticated && editMode && fieldValues.habitat !== undefined ? (
                                                    <>
                                                        <textarea
                                                            rows="4" cols="50"
                                                            ref={inputRef}
                                                            type="text"
                                                            defaultValue={fieldValues.habitat}
                                                            onChange={(e) => setFieldValues({ ...fieldValues, habitat: e.target.value })}
                                                            onKeyDown={(e) => handleKeyDown('habitat', e)}
                                                            style={{ borderBottom: '1px dashed #000', cursor: 'text' }}
                                                        />
                                                        <button onClick={() => handleSaveClick('habitat')}>Salvesta
                                                        </button>
                                                        <button onClick={handleCancelClick}>Tühista</button>
                                                    </>
                                                ) : ( authenticated &&
                                                    <button onClick={() => handleEditClick('habitat', fishes.habitat)}>Muuda</button>
                                                )}
                                            </div>
                                        )}

                                        {fishes.diet && (
                                            <div>
                                            <p><b>Toitumine:</b> {' '} {fishes.diet}</p>
                                                {authenticated && editMode && fieldValues.diet !== undefined ? (
                                                    <>
                                                        <textarea
                                                            rows="4" cols="50"
                                                            ref={inputRef}
                                                            type="text"
                                                            defaultValue={fieldValues.diet}
                                                            onChange={(e) => setFieldValues({ ...fieldValues, diet: e.target.value })}
                                                            onKeyDown={(e) => handleKeyDown('diet', e)}
                                                            style={{ borderBottom: '1px dashed #000', cursor: 'text' }}
                                                        />
                                                        <button onClick={() => handleSaveClick('diet')}>Salvesta
                                                        </button>
                                                        <button onClick={handleCancelClick}>Tühista</button>
                                                    </>
                                                ) : (
                                                    authenticated &&
                                                    <button onClick={() => handleEditClick('diet', fishes.diet)}>Muuda</button>
                                                )}
                                            </div>
                                        )}

                                        {fishes.behavior && (
                                            <div>
                                            <p><b>Käitumine:</b> {' '} {fishes.behavior}</p>
                                                {authenticated && editMode && fieldValues.behavior !== undefined ? (
                                                    <>
                                                        <textarea
                                                            rows="4" cols="50"
                                                            ref={inputRef}
                                                            type="text"
                                                            defaultValue={fieldValues.behavior}
                                                            onChange={(e) => setFieldValues({ ...fieldValues, behavior: e.target.value })}
                                                            onKeyDown={(e) => handleKeyDown('behavior', e)}
                                                            style={{ borderBottom: '1px dashed #000', cursor: 'text' }}
                                                        />
                                                        <button onClick={() => handleSaveClick('behavior')}>Salvesta
                                                        </button>
                                                        <button onClick={handleCancelClick}>Tühista</button>
                                                    </>
                                                ) : (
                                                    authenticated &&
                                                    <button onClick={() => handleEditClick('behavior', fishes.behavior)}>Muuda</button>
                                                )}
                                            </div>
                                        )}

                                        {fishes.reproduction && (
                                            <div>
                                            <p><b>Paljunemine:</b> {' '} {fishes.reproduction}</p>
                                                {authenticated && editMode && fieldValues.reproduction !== undefined ? (
                                                    <>
                                                        <textarea
                                                            rows="4" cols="50"
                                                            ref={inputRef}
                                                            type="text"
                                                            defaultValue={fieldValues.reproduction}
                                                            onChange={(e) => setFieldValues({ ...fieldValues, reproduction: e.target.value })}
                                                            onKeyDown={(e) => handleKeyDown('reproduction', e)}
                                                            style={{ borderBottom: '1px dashed #000', cursor: 'text' }}
                                                        />
                                                        <button onClick={() => handleSaveClick('reproduction')}>Salvesta
                                                        </button>
                                                        <button onClick={handleCancelClick}>Tühista</button>
                                                    </>
                                                ) : (
                                                    authenticated &&
                                                    <button onClick={() => handleEditClick('reproduction', fishes.reproduction)}>Muuda</button>
                                                )}
                                            </div>
                                        )}

                                        {fishes.migration && (
                                            <div>
                                            <p><b>Ränne:</b> {' '} {fishes.migration}</p>
                                                {authenticated && editMode && fieldValues.migration !== undefined ? (
                                                    <>
                                                        <textarea
                                                            rows="4" cols="50"
                                                            ref={inputRef}
                                                            type="text"
                                                            defaultValue={fieldValues.migration}
                                                            onChange={(e) => setFieldValues({ ...fieldValues, migration: e.target.value })}
                                                            onKeyDown={(e) => handleKeyDown('migration', e)}
                                                            style={{ borderBottom: '1px dashed #000', cursor: 'text' }}
                                                        />
                                                        <button onClick={() => handleSaveClick('migration')}>Salvesta
                                                        </button>
                                                        <button onClick={handleCancelClick}>Tühista</button>
                                                    </>
                                                ) : (
                                                    authenticated &&
                                                    <button onClick={() => handleEditClick('migration', fishes.migration)}>Muuda</button>
                                                )}
                                            </div>
                                        )}

                                        {fishes.population_biology && (
                                            <div>
                                            <p><b>Populatsiooni bioloogia:</b> {' '} {fishes.population_biology}</p>
                                                {authenticated && editMode && fieldValues.population_biology !== undefined ? (
                                                    <>
                                                        <textarea
                                                            rows="4" cols="50"
                                                            ref={inputRef}
                                                            type="text"
                                                            defaultValue={fieldValues.population_biology}
                                                            onChange={(e) => setFieldValues({ ...fieldValues, population_biology: e.target.value })}
                                                            onKeyDown={(e) => handleKeyDown('population_biology', e)}
                                                            style={{ borderBottom: '1px dashed #000', cursor: 'text' }}
                                                        />
                                                        <button onClick={() => handleSaveClick('population_biology')}>Salvesta
                                                        </button>
                                                        <button onClick={handleCancelClick}>Tühista</button>
                                                    </>
                                                ) : (
                                                    authenticated &&
                                                    <button onClick={() => handleEditClick('population_biology', fishes.population_biology)}>Muuda</button>
                                                )}
                                            </div>
                                        )}

                                        {fishes.spread && (
                                            <div>
                                            <p><b>Levik:</b> {' '} {fishes.spread}</p>
                                                {authenticated && editMode && fieldValues.spread !== undefined ? (
                                                    <>
                                                        <textarea
                                                            rows="4" cols="50"
                                                            ref={inputRef}
                                                            type="text"
                                                            defaultValue={fieldValues.spread}
                                                            onChange={(e) => setFieldValues({ ...fieldValues, spread: e.target.value })}
                                                            onKeyDown={(e) => handleKeyDown('spread', e)}
                                                            style={{ borderBottom: '1px dashed #000', cursor: 'text' }}
                                                        />
                                                        <button onClick={() => handleSaveClick('spread')}>Salvesta
                                                        </button>
                                                        <button onClick={handleCancelClick}>Tühista</button>
                                                    </>
                                                ) : (
                                                    authenticated &&
                                                    <button onClick={() => handleEditClick('spread', fishes.spread)}>Muuda</button>
                                                )}
                                            </div>
                                        )}

                                        {fishes.protection_and_restriction && (
                                            <div>
                                            <p><b>Kaitse ja piirangud:</b> {' '} {fishes.protection_and_restriction}</p>
                                                {authenticated && editMode && fieldValues.protection_and_restriction !== undefined ? (
                                                    <>
                                                        <textarea
                                                            rows="4" cols="50"
                                                            ref={inputRef}
                                                            type="text"
                                                            defaultValue={fieldValues.protection_and_restriction}
                                                            onChange={(e) => setFieldValues({ ...fieldValues, protection_and_restriction: e.target.value })}
                                                            onKeyDown={(e) => handleKeyDown('protection_and_restriction', e)}
                                                            style={{borderBottom: '1px dashed #000', cursor: 'text'}}
                                                        />
                                                        <button onClick={() => handleSaveClick('protection_and_restriction')}>Salvesta
                                                        </button>
                                                        <button onClick={handleCancelClick}>Tühista</button>
                                                    </>
                                                ) : (
                                                    authenticated &&
                                                    <button onClick={() => handleEditClick('protection_and_restriction', fishes.protection_and_restriction)}>Muuda</button>
                                                )}
                                            </div>
                                        )}

                                        {fishes.status && (
                                            <div>
                                            <p><b>Seisund:</b> {' '} {fishes.status}</p>
                                                {authenticated && editMode && fieldValues.status !== undefined ? (
                                                    <>
                                                        <textarea
                                                            rows="4" cols="50"
                                                            ref={inputRef}
                                                            type="text"
                                                            defaultValue={fieldValues.status}
                                                            onChange={(e) => setFieldValues({ ...fieldValues, status: e.target.value })}
                                                            onKeyDown={(e) => handleKeyDown('status', e)}
                                                            style={{ borderBottom: '1px dashed #000', cursor: 'text' }}
                                                        />
                                                        <button onClick={() => handleSaveClick('status')}>Salvesta
                                                        </button>
                                                        <button onClick={handleCancelClick}>Tühista</button>
                                                    </>
                                                ) : (
                                                    authenticated &&
                                                    <button onClick={() => handleEditClick('status', fishes.status)}>Muuda</button>
                                                )}
                                            </div>
                                        )}

                                        {fishes.importance && (
                                            <div>
                                            <p><b>Tähtsus:</b> {' '} {fishes.importance}</p>
                                                {authenticated && editMode && fieldValues.importance !== undefined ? (
                                                    <>
                                                        <textarea
                                                            rows="4" cols="50"
                                                            ref={inputRef}
                                                            type="text"
                                                            defaultValue={fieldValues.importance}
                                                            onChange={(e) => setFieldValues({ ...fieldValues, importance: e.target.value })}
                                                            onKeyDown={(e) => handleKeyDown('importance', e)}
                                                            style={{ borderBottom: '1px dashed #000', cursor: 'text' }}
                                                        />
                                                        <button onClick={() => handleSaveClick('importance')}>Salvesta
                                                        </button>
                                                        <button onClick={handleCancelClick}>Tühista</button>
                                                    </>
                                                ) : (
                                                    authenticated &&
                                                    <button onClick={() => handleEditClick('importance', fishes.importance)}>Muuda</button>
                                                )}
                                            </div>
                                        )}

                                        {fishes.additonal_info && (
                                            <div>
                                            <p><b>Lisainfo:</b> {' '} {fishes.additonal_info}</p>
                                                {authenticated && editMode && fieldValues.additional_info !== undefined ? (
                                                    <>
                                                        <textarea
                                                            rows="4" cols="50"
                                                            ref={inputRef}
                                                            type="text"
                                                            defaultValue={fieldValues.additonal_info}
                                                            onChange={(e) => setFieldValues({ ...fieldValues, additional_info: e.target.value })}
                                                            onKeyDown={(e) => handleKeyDown('additional_info', e)}
                                                            style={{ borderBottom: '1px dashed #000', cursor: 'text' }}
                                                        />
                                                        <button onClick={() => handleSaveClick('additional')}>Salvesta
                                                        </button>
                                                        <button onClick={handleCancelClick}>Tühista</button>
                                                    </>
                                                ) : (
                                                    authenticated &&
                                                    <button onClick={() => handleEditClick('additional', fishes.additional_info)}>Muuda</button>
                                                )}
                                            </div>
                                        )}

                                        {species && (
                                            <dl>
                                                <p><b>Asend looduses:</b></p>
                                                {species.lineage.split(", ").map((value, index) => {
                                                    const label = labels[index] || `Label ${index + 1}`;
                                                    return (
                                                        <li key={index}>
                                                            <span>{label}:</span> <i>{value}</i>
                                                        </li>
                                                    );
                                                })}
                                            </dl>
                                        )}
                                    </li>

                                </ul>
                            ) : (
                                <p>No data available.</p>
                            )}
                        </div>
                        <a onClick={() => navigate(-1)}>&lt; Tagasi</a>
                    </div>
                </div>
            </div>

        </>
    );
}

export default ItemPage;
